import type {
  MultiChannelFormRow,
  PatientCaseIdPair,
  SingleChannelFormRow,
} from './form.state.types';

export const getFirstMatchingPairOrWsisGroup = <T extends PatientCaseIdPair>(
  localSlide: SingleChannelFormRow | MultiChannelFormRow,
  matchingWsisInDb: T[]
): T | undefined => {
  const { patientExternalId, caseId } =
    localSlide.type === 'single-channel'
      ? localSlide
      : localSlide.parentAssigned;

  return matchingWsisInDb.find(
    (group) =>
      group.patientExternalId === patientExternalId && group.caseId === caseId
  );
};
