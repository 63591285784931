import { CategoryItem, VStack } from '@aignostics/components';
import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

interface Organization {
  uuid: string;
  name: string;
}

interface OrganizationSelectorProps {
  organizations: Organization[];
  initialActiveOrganizationUuid: string | null;
}

export const OrganizationSelector = ({
  organizations,
  initialActiveOrganizationUuid,
}: OrganizationSelectorProps): ReactElement => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [activeOrganization, setActiveOrganization] = useState<string | null>(
    initialActiveOrganizationUuid
  );

  const handleSetActiveOrganization = (uuid: string, name: string) => {
    setActiveOrganization(uuid);
    const targetOrg = organizations.find(({ uuid: orgId }) => orgId === uuid);
    if (targetOrg === undefined) {
      throw new Error(
        `Organization ${name} was selected but is not available for the current user`
      );
    }
    void navigate(`/${targetOrg.uuid}/`);
  };

  return (
    <VStack style={{ width: '100%', padding: '8px 16px' }}>
      {organizations.map(({ uuid, name }) => (
        <CategoryItem
          name={name
            .split(' ')
            .map((word) => word[0].toUpperCase() + word.slice(1))
            .join(' ')}
          active={activeOrganization === uuid}
          setActive={() => {
            handleSetActiveOrganization(uuid, name);
          }}
          componentTheme="white"
          color={
            activeOrganization === uuid
              ? theme.colors.primary
              : theme.colors.transparent
          }
          key={uuid}
        />
      ))}
    </VStack>
  );
};
