import { pluralize } from '@aignostics/utils';
import {
  difference as _difference,
  differenceWith as _differenceWith,
} from 'lodash';
import { parse } from 'papaparse';
import * as uuid from 'uuid';
import type {
  CancerSite,
  Disease,
  Morphology,
  PreparationType,
  SampleType,
  Scanner,
  Staining,
  Tissue,
} from '../../../types';
import { displayScannerName } from '../../../utils/displayScannerName';
import { overrideHEStaining } from '../Form';
import {
  DEFAULT_CANCER_SITE_VALUE,
  DEFAULT_DISEASE_VALUE,
  DEFAULT_MORPHOLOGY_VALUE,
  DEFAULT_PREPARATION_TYPE_VALUE,
  DEFAULT_SAMPLE_TYPE_VALUE,
} from '../const';
import { isInt32Number } from './isInt32Number';

export const MULTIPLE_STAININGS_SEPARATOR = '|';

const REQUIRED_COLUMNS = [
  'Filename',
  'Scanner',
  'Staining',
  'Localization',
  'Patient ID',
  'Case ID',
  'Block',
  'Section',
  'Disease',
  'Preparation Type',
  'Sample Type',
] as const;

const oldColumnMapping = new Map<string, string>([
  ['filename', 'Filename'],
  ['scanner', 'Scanner'],
  ['staining', 'Staining'],
  ['tissue', 'Localization'],
  ['patientExternalId', 'Patient ID'],
  ['case id', 'Case ID'],
  ['block', 'Block'],
  ['section', 'Section'],
  ['disease', 'Disease'],
  ['samplePreparation', 'Preparation Type'],
  ['sampleType', 'Sample Type'],
  ['parentTmaRow', 'parent_tma_row'],
  ['parentTmaCol', 'parent_tma_col'],
  ['parentSlidePosX', 'parent_slide_pos_x'],
  ['parentSlidePosY', 'parent_slide_pos_y'],
  ['parentWsiUuid', 'parent_wsi_uuid'],
  ['wsiUuid', 'wsi_uuid'],
  ['caseUuid', 'case_uuid'],
  ['morphology', 'Morphology'],
  ['cancerSite', 'Cancer Site'],
]);

/** mapping parser csv columns back to form state columns */
export const newColumnMapping = new Map<string, string>(
  [...oldColumnMapping].map(([oldColumn, newColumn]) => {
    let resultNewColumn = newColumn;
    let resultOldColumn = oldColumn;
    if (newColumn === 'Scanner') {
      resultNewColumn = 'scannerId';
      resultOldColumn = 'scannerId';
    } else if (newColumn === 'Case ID') {
      resultOldColumn = 'caseId';
    }

    return [resultNewColumn, resultOldColumn];
  })
);

const OPTIONAL_COLUMNS_STRING = ['Morphology', 'Cancer Site'] as const;

const OPTIONAL_COLUMNS_INT32 = [
  'parent_tma_row',
  'parent_tma_col',
  'parent_slide_pos_x',
  'parent_slide_pos_y',
] as const;

const OPTIONAL_COLUMNS_UUID = [
  'parent_wsi_uuid',
  'wsi_uuid',
  'case_uuid',
] as const;

const OPTIONAL_COLUMNS = [
  ...OPTIONAL_COLUMNS_STRING,
  ...OPTIONAL_COLUMNS_INT32,
  ...OPTIONAL_COLUMNS_UUID,
] as const;

type CSVIntegerColumns = (typeof OPTIONAL_COLUMNS_INT32)[number];
type CSVColumnRequired = (typeof REQUIRED_COLUMNS)[number];
type CSVColumnOptional = (typeof OPTIONAL_COLUMNS)[number];
type CSVColumn = CSVColumnRequired | CSVColumnOptional;
type CSVNullableColumns =
  | Extract<
      CSVColumnRequired,
      | 'Scanner'
      | 'Staining'
      | 'Localization'
      | 'Patient ID'
      | 'Block'
      | 'Section'
      | 'Disease'
      | 'Preparation Type'
      | 'Sample Type'
      | 'Morphology'
      | 'Cancer Site'
    >
  | CSVColumnOptional;

type CSVNonNullableColumns = Exclude<CSVColumn, CSVNullableColumns>;
type CSVWSIRow = Record<CSVColumn, string>;
// csv parser results have nullable fields
// csv parser results would have scannerId instead of scannerModel & scannerVendor
type ResultNullableColumns =
  | Exclude<CSVNullableColumns, 'Scanner'>
  | 'scannerId';

// prettier-ignore
export type CSVParseResultWSIRow =
  Omit<
    (Record<CSVNonNullableColumns, string> &
     Record<ResultNullableColumns, string | null>),
    CSVIntegerColumns
  >
  &
  Pick<
    (Record<CSVNonNullableColumns, number> &
     Record<ResultNullableColumns, number | null>),
    CSVIntegerColumns
  >;

export type CSVParserInputWRIRow = CSVParseResultWSIRow & {
  type: 'single-channel' | 'multi-channel';
};

const getMismatchedColumnsError = ({
  missingColumns,
  extraneousColumns,
}: {
  missingColumns: string[];
  extraneousColumns: string[];
}): string => {
  let msg = 'Mismatching columns in the CSV';
  if (missingColumns.length > 0) {
    msg += `. Missing: ${missingColumns.map((c) => `"${c}"`).join(', ')}`;
  }
  if (extraneousColumns.length > 0) {
    msg += `. Extraneous: ${extraneousColumns.map((c) => `"${c}"`).join(', ')}`;
  }

  return msg;
};

/* eslint-disable-next-line jsdoc/check-line-alignment */
/**
 * Normalizes passed entity to the map with lowercased value keys:
 *
 * Example:
 *
 * ```
 * Map {
 *   'bone marrow' => { id: '14', name: 'Bone Marrow' },
 *   'breast' => { id: '1', name: 'Breast' },
 *   'colon' => { id: '2', name: 'Colon' }
 * }
 * ```
 */
const normalizeEntities = <Entity, Prop extends keyof Entity>(
  entities: Entity[],
  field: Prop
): Map<Entity[Prop], Entity> => {
  const result = new Map<Entity[Prop], Entity>();

  for (const entity of entities) {
    const entityValue = entity[field];
    if (entityValue !== null) {
      const normalizedEntityValue =
        typeof entityValue === 'string'
          ? (entityValue.toLowerCase() as unknown as Entity[Prop])
          : entityValue;
      result.set(normalizedEntityValue, entity);
    }
  }

  return result;
};

const dropFirstLine = async (fileOrText: File | string): Promise<string> => {
  const text =
    typeof fileOrText === 'string' ? fileOrText : await fileOrText.text();
  const newLineIdx = text.indexOf('\n');

  if (newLineIdx !== -1) {
    return text.slice(newLineIdx + 1);
  }

  return '';
};

export type ParserResultSuccess = {
  ok: true;
  data: CSVParseResultWSIRow[];
  csvLineMapping: (number | null)[];
  warnings: string[];
};
type ParserResultFail = {
  ok: false;
  error: string;
};
type ParserResult = ParserResultSuccess | ParserResultFail;

export const csvParser = (
  csvFile: File | string,
  wsis: CSVParserInputWRIRow[],
  tissues: Tissue[],
  stainings: Staining[],
  scanners: Scanner[],
  diseases: Disease[],
  preparationTypes: PreparationType[],
  sampleTypes: SampleType[],
  morphologies: Morphology[],
  cancerSites: CancerSite[],
  secondAttemptWithStrippedFirstLine = false
): Promise<ParserResult> =>
  new Promise<ParserResult>((resolve) => {
    const CSV_INDEX_OFFSET = secondAttemptWithStrippedFirstLine ? 3 : 2;

    parse<CSVWSIRow>(csvFile, {
      header: true,
      skipEmptyLines: true,
      transformHeader(header) {
        const columnMappingValue = oldColumnMapping.get(header);
        if (columnMappingValue) {
          return columnMappingValue;
        }

        return header;
      },

      // eslint-disable-next-line sonarjs/cognitive-complexity
      complete({ data, meta, errors }) {
        // if we had detected only 1 field, that's probably a table header
        if (
          // first field is non-empty
          meta.fields![0] &&
          // rest of the fields are empty
          meta.fields!.slice(1).every((fieldName) => !fieldName) &&
          !secondAttemptWithStrippedFirstLine
        ) {
          void dropFirstLine(csvFile).then((csvTextWithoutFirstLine) => {
            resolve(
              csvParser(
                csvTextWithoutFirstLine,
                wsis,
                tissues,
                stainings,
                scanners,
                diseases,
                preparationTypes,
                sampleTypes,
                morphologies,
                cancerSites,
                true
              )
            );
          });

          return;
        }

        // backwards compatibility for csv files with separate columns for scannerVendor & scannerModel.
        // delete this block, parser.scannerVendorAndScannerModelCompat.spec.ts and fixtures/scannerVendorAndScannerModelCompat
        // when it's not needed anymore
        if (
          meta.fields!.includes('scannerVendor') &&
          meta.fields!.includes('scannerModel') &&
          !meta.fields!.includes('Scanner')
        ) {
          meta.fields = meta.fields!.filter(
            (field) => field !== 'scannerVendor' && field !== 'scannerModel'
          );

          meta.fields.push('Scanner');

          // replace scannerVendor & scannerModel with unified scanner
          (
            data as (CSVWSIRow &
              Record<'scannerVendor' | 'scannerModel', string>)[]
          ).forEach((datum) => {
            datum.Scanner = displayScannerName({
              vendor: datum.scannerVendor,
              model: datum.scannerModel,
            });

            // @ts-expect-error we want to delete those keys
            delete datum.scannerVendor;
            // @ts-expect-error here too
            delete datum.scannerModel;
          });
        }

        if (errors.length > 0) {
          let error = 'CSV parsing error (malformed input data)';

          // display error message if there is only one error
          if (errors.length === 1) {
            error += `: ${errors[0].message}`;
          }

          resolve({ ok: false, error });

          return;
        }

        const csvColumns = meta.fields ?? [];

        // check columns, hard fail
        const missingColumns = _difference(REQUIRED_COLUMNS, csvColumns);
        const extraneousColumns = _difference(csvColumns, [
          ...REQUIRED_COLUMNS,
          ...OPTIONAL_COLUMNS,
        ]);
        const hasMorphologyColumn = csvColumns.includes('Morphology');
        const hasCancerSiteColumn = csvColumns.includes('Cancer Site');

        if (missingColumns.length > 0 || extraneousColumns.length > 0) {
          resolve({
            ok: false,
            error: getMismatchedColumnsError({
              missingColumns,
              extraneousColumns,
            }),
          });

          return;
        }

        const warnings: string[] = [];

        if (!hasMorphologyColumn) {
          warnings.push(
            'CSV file is missing optional "Morphology" column, using default value'
          );
        }

        if (!hasCancerSiteColumn) {
          warnings.push(
            'CSV file is missing optional "Cancer Site" column, using default value'
          );
        }

        const csvLineMapping: ParserResultSuccess['csvLineMapping'] = [];

        const wsisFilenames = wsis.map((wsi) => wsi.Filename);
        const csvFilenames = data.map((datum) => datum.Filename);
        const missingsRows = _difference(wsisFilenames, csvFilenames);
        if (missingsRows.length > 0) {
          warnings.push(
            // prettier-ignore
            `Missing ${pluralize('row', missingsRows.length)} in the CSV for files: ${missingsRows
              .slice().sort((a, b) => a.localeCompare(b))
              .join(', ')}`
          );
        }

        const extraneousRows = _differenceWith(
          csvFilenames.map(
            (csvFilename, index) => [csvFilename, index] as [string, number]
          ),
          wsisFilenames,
          ([csvFilename], wsiFilename) => csvFilename === wsiFilename
        );
        if (extraneousRows.length > 0) {
          warnings.push(
            // prettier-ignore
            `Extraneous ${pluralize('row', extraneousRows.length)} in the CSV for ${pluralize('file', extraneousRows.length)}: ${extraneousRows
              .map(([filename]) => filename)
              .sort((a, b) => a.localeCompare(b))
              .join(', ')} (CSV line ${pluralize('number', extraneousRows.length)}: ${extraneousRows
                .map(([, index]) => index + CSV_INDEX_OFFSET)
                .sort((a, b) => a - b)
                .join(', ')})`
          );
        }

        // drop type property from passed wsis
        const result: CSVParseResultWSIRow[] = wsis.map((wsi) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { type, ...restOfWsi } = wsi;
          return restOfWsi;
        });

        const normalizedTissues = normalizeEntities(tissues, 'name');
        const normalizedStainings = normalizeEntities(stainings, 'name');
        const normalizedScanners = normalizeEntities(
          scanners.map((scanner) => ({
            ...scanner,
            displayName: displayScannerName(scanner),
          })),
          'displayName'
        );
        const normalizedDiseases = normalizeEntities(diseases, 'name');
        const normalizedPreparationTypes = normalizeEntities(
          preparationTypes,
          'name'
        );
        const normalizedSampleTypes = normalizeEntities(sampleTypes, 'name');
        // morphologies can be matched by display name and code only
        const normalizedMorphologies = new Map([
          ...normalizeEntities(morphologies, 'displayName'),
          ...normalizeEntities(morphologies, 'code'),
        ]);
        const normalizedCancerSites = normalizeEntities(cancerSites, 'name');

        const warningsTissuesCsvLines: number[] = [];
        const warningsStainingsCsvLines: number[] = [];
        const warningsWrongCountOfStainingsCsvLines: number[] = [];
        const warningsScannersCsvLines: number[] = [];
        const warningsDiseasesCsvLines: number[] = [];
        const warningsPreparationTypesCsvLines: number[] = [];
        const warningsSampleTypesCsvLines: number[] = [];
        const warningsMorphologiesCsvLines: number[] = [];
        const warningsCancerSitesCsvLines: number[] = [];

        for (const [wsiIndex, wsi] of wsis.entries()) {
          const csvRowIndex = csvFilenames.indexOf(wsi.Filename);
          if (csvRowIndex === -1) {
            csvLineMapping.push(null);
          } else {
            const datum = data[csvRowIndex];

            // optional values used for TMA upload specified in CSV must conform to:
            // parent_tma_row: signed 32bit int,
            // parent_tma_col: signed 32bit int,
            // parent_slide_pos_x: signed 32bit int,
            // parent_slide_pos_y: signed 32bit int,
            // parent_wsi_uuid: uuid,
            // wsi_uuid: uuid,
            // case_uuid: uuid
            // if any of those values are not conforms (if they're specified in the csv)
            // we would return parsing error
            for (const optionalColumnInt32 of OPTIONAL_COLUMNS_INT32) {
              const rawValue = datum[optionalColumnInt32];
              if (
                // value must be truthy
                rawValue &&
                !isInt32Number(Number(datum[optionalColumnInt32]))
              ) {
                resolve({
                  ok: false,
                  error: `CSV parsing error (malformed input data) ${optionalColumnInt32} on csv line ${csvRowIndex + CSV_INDEX_OFFSET}: "${rawValue}" is not Int32`,
                });

                return;
              }
            }
            for (const optionalColumnUuid of OPTIONAL_COLUMNS_UUID) {
              const rawValue = datum[optionalColumnUuid];
              if (
                // value must be truthy
                rawValue &&
                !uuid.validate(rawValue)
              ) {
                resolve({
                  ok: false,
                  error: `CSV parsing error (malformed input data) ${optionalColumnUuid} on csv line ${csvRowIndex + CSV_INDEX_OFFSET}: "${rawValue}" is not UUID`,
                });

                return;
              }
            }

            const isMatchingTissue = normalizedTissues.has(
              datum.Localization.toLowerCase()
            );
            if (!isMatchingTissue) {
              warningsTissuesCsvLines.push(csvRowIndex + CSV_INDEX_OFFSET);
            }

            // map he to h&e
            datum.Staining =
              overrideHEStaining(stainings, datum.Staining) ?? '';
            const csvStainings = datum.Staining.split(
              MULTIPLE_STAININGS_SEPARATOR
            );
            const wsiStainings = (wsi.Staining ?? '').split(
              MULTIPLE_STAININGS_SEPARATOR
            );
            let isMatchingStainings: boolean;
            if (csvStainings.length !== wsiStainings.length) {
              // add warning when count of stainings for multichannel slide
              // file differs from the one we have in the data upload ui
              isMatchingStainings = false;
              warningsWrongCountOfStainingsCsvLines.push(
                csvRowIndex + CSV_INDEX_OFFSET
              );
            } else {
              isMatchingStainings = csvStainings.every((s) =>
                normalizedStainings.has(s.toLowerCase())
              );
              if (!isMatchingStainings) {
                warningsStainingsCsvLines.push(csvRowIndex + CSV_INDEX_OFFSET);
              }
            }

            // only check for mismatch if value is not empty, if it is empty shouldn't show a warning
            const isMatchingDisease = !isEmptyValue(datum.Disease)
              ? normalizedDiseases.has(datum.Disease.toLowerCase())
              : true;
            if (!isMatchingDisease) {
              warningsDiseasesCsvLines.push(csvRowIndex + CSV_INDEX_OFFSET);
            }

            const isMatchingPreparationType = !isEmptyValue(
              datum['Preparation Type']
            )
              ? normalizedPreparationTypes.has(
                  datum['Preparation Type'].toLowerCase()
                )
              : true;
            if (!isMatchingPreparationType) {
              warningsPreparationTypesCsvLines.push(
                csvRowIndex + CSV_INDEX_OFFSET
              );
            }

            const isMatchingSampleType = !isEmptyValue(datum['Sample Type'])
              ? normalizedSampleTypes.has(datum['Sample Type'].toLowerCase())
              : true;
            if (!isMatchingSampleType) {
              warningsSampleTypesCsvLines.push(csvRowIndex + CSV_INDEX_OFFSET);
            }

            const isMatchingMorphology = !isEmptyValue(datum['Morphology'])
              ? normalizedMorphologies.has(datum['Morphology'].toLowerCase())
              : true;
            if (!isMatchingMorphology) {
              warningsMorphologiesCsvLines.push(csvRowIndex + CSV_INDEX_OFFSET);
            }

            const isMatchingCancerSite = !isEmptyValue(datum['Cancer Site'])
              ? normalizedCancerSites.has(datum['Cancer Site'].toLowerCase())
              : true;
            if (!isMatchingCancerSite) {
              warningsCancerSitesCsvLines.push(csvRowIndex + CSV_INDEX_OFFSET);
            }

            const isMatchingScanner = normalizedScanners.has(
              datum.Scanner.toLowerCase()
            );

            if (!isMatchingScanner) {
              warningsScannersCsvLines.push(csvRowIndex + CSV_INDEX_OFFSET);
            }

            csvLineMapping.push(csvRowIndex + CSV_INDEX_OFFSET);

            const isWsiUuidSetForMultiChannel =
              wsi.type === 'multi-channel' && !isEmptyValue(datum.wsi_uuid);
            if (isWsiUuidSetForMultiChannel) {
              warnings.push(
                `Can't set wsi_uuid for multichannel slidefile "${datum.Filename}"`
              );
            }

            result[wsiIndex] = {
              Filename: datum.Filename,
              Localization: isMatchingTissue
                ? normalizedTissues.get(datum.Localization.toLowerCase())!.name
                : null,
              Staining:
                wsiStainings.length > 1
                  ? datum.Staining
                  : isMatchingStainings
                    ? normalizedStainings.get(datum.Staining.toLowerCase())!
                        .name
                    : null,
              scannerId: isMatchingScanner
                ? normalizedScanners.get(datum.Scanner.toLowerCase())!.id
                : null,
              'Case ID':
                datum['Case ID'] && datum['Case ID'].length > 0
                  ? datum['Case ID']
                  : datum.Filename.slice(0, datum.Filename.lastIndexOf('.')),
              'Patient ID': datum['Patient ID'],
              Block: datum.Block,
              Section: datum.Section,
              Disease: !isEmptyValue(datum.Disease)
                ? isMatchingDisease
                  ? normalizedDiseases.get(datum.Disease.toLowerCase())!.name
                  : null
                : DEFAULT_DISEASE_VALUE,
              'Preparation Type': !isEmptyValue(datum['Preparation Type'])
                ? isMatchingPreparationType
                  ? normalizedPreparationTypes.get(
                      datum['Preparation Type'].toLowerCase()
                    )!.name
                  : null
                : DEFAULT_PREPARATION_TYPE_VALUE,
              'Sample Type': !isEmptyValue(datum['Sample Type'])
                ? isMatchingSampleType
                  ? normalizedSampleTypes.get(
                      datum['Sample Type'].toLowerCase()
                    )!.name
                  : null
                : DEFAULT_SAMPLE_TYPE_VALUE,
              Morphology: hasMorphologyColumn
                ? !isEmptyValue(datum['Morphology'])
                  ? isMatchingMorphology
                    ? normalizedMorphologies.get(
                        datum['Morphology'].toLowerCase()
                      )!.code
                    : null
                  : DEFAULT_MORPHOLOGY_VALUE
                : DEFAULT_MORPHOLOGY_VALUE,
              'Cancer Site': hasCancerSiteColumn
                ? !isEmptyValue(datum['Cancer Site'])
                  ? isMatchingCancerSite
                    ? normalizedCancerSites.get(
                        datum['Cancer Site'].toLowerCase()
                      )!.name
                    : null
                  : DEFAULT_CANCER_SITE_VALUE
                : DEFAULT_CANCER_SITE_VALUE,
              parent_tma_row: !isEmptyValue(datum.parent_tma_row)
                ? Number(datum.parent_tma_row)
                : null,
              parent_tma_col: !isEmptyValue(datum.parent_tma_col)
                ? Number(datum.parent_tma_col)
                : null,
              parent_slide_pos_x: !isEmptyValue(datum.parent_slide_pos_x)
                ? Number(datum.parent_slide_pos_x)
                : null,
              parent_slide_pos_y: !isEmptyValue(datum.parent_slide_pos_y)
                ? Number(datum.parent_slide_pos_y)
                : null,
              parent_wsi_uuid: !isEmptyValue(datum.parent_wsi_uuid)
                ? datum.parent_wsi_uuid
                : null,
              // don't set wsi_uuid value for multichannel slide files
              wsi_uuid: isWsiUuidSetForMultiChannel
                ? null
                : !isEmptyValue(datum.wsi_uuid)
                  ? datum.wsi_uuid
                  : null,
              case_uuid: !isEmptyValue(datum.case_uuid)
                ? datum.case_uuid
                : null,
            };
          }
        }

        (
          [
            ['Localizations', warningsTissuesCsvLines],
            ['Stainings', warningsStainingsCsvLines],
            ['Scanners', warningsScannersCsvLines],
            ['Diseases', warningsDiseasesCsvLines],
            ['Preparation Types', warningsPreparationTypesCsvLines],
            ['Sample Types', warningsSampleTypesCsvLines],
            ['Morphology', warningsMorphologiesCsvLines],
            ['Cancer Site', warningsCancerSitesCsvLines],
          ] as const
        ).forEach(([entity, warningsCsvLines]) => {
          if (warningsCsvLines.length > 0) {
            warnings.push(
              `Bad ${pluralize(
                'value',
                warningsCsvLines.length
              )} for ${entity} on CSV ${pluralize(
                'line',
                warningsCsvLines.length
              )} ${warningsCsvLines.join(', ')}`
            );
          }
        });

        if (warningsWrongCountOfStainingsCsvLines.length > 0) {
          warnings.push(
            `Mismatching count of channels for slide file on CSV ${pluralize(
              'line',
              warningsWrongCountOfStainingsCsvLines.length
            )} ${warningsWrongCountOfStainingsCsvLines.join(', ')}`
          );
        }

        resolve({
          ok: true,
          data: result,
          csvLineMapping,
          warnings,
        });
      },
      error(error) {
        resolve({
          ok: false,
          error: `CSV parsing error: ${error.message}`,
        });
      },
    });
  });

function isEmptyValue(value: unknown): boolean {
  // if truthy – return false
  return !value;
}
