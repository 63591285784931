import { Modal } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { AnnotationCategoryInput, AnnotationCategorySet } from '../../types';
import { AnnotationCategoryForm } from './AnnotationCategoryForm.component';

export const CreateAnnotationCategoryModal = ({
  isOpen,
  onClose,
  onCreateAnnotationCategory,
  localAnnotationCategorySets,
  localAnnotationCategoryNames,
}: {
  isOpen: boolean;
  onClose: () => void;
  onCreateAnnotationCategory: (
    newAnnotationCategory: AnnotationCategoryInput
  ) => void;
  localAnnotationCategorySets?: AnnotationCategorySet[];
  localAnnotationCategoryNames?: string[];
}): ReactElement => {
  return (
    <Modal
      isVisible={isOpen}
      onClose={onClose}
      shouldCloseOnEscKey
      hasCloseButton
    >
      <AnnotationCategoryForm
        mode="create"
        onSubmit={onCreateAnnotationCategory}
        localAnnotationCategorySets={localAnnotationCategorySets}
        localAnnotationCategoryNames={localAnnotationCategoryNames}
      />
    </Modal>
  );
};
