import {
  Placeholder,
  TableComponent,
  TableHeaderType,
  TableSkeleton,
} from '@aignostics/components';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SortBy, SortByDirection } from '../../../hooks';
import {
  AnnotationCategorySet,
  AnnotationCategorySetSortByOptions,
} from '../../../types';
import { $TableContainer } from '../AnnotationManagement.component.style';
import { PAGE_SIZE } from './AnnotationCategorySets.component';
import { AnnotationCategorySetsTableRowActions } from './AnnotationCategorySetsTableRowActions.component';

const $TableLink = styled(Link)`
  text-decoration: none;
`;

export interface AnnotationCategorySetsTableProps {
  loading: boolean;
  annotationCategorySets: AnnotationCategorySet[];
  sortBy: SortBy<AnnotationCategorySetSortByOptions>;
  setSortByOptions: (
    sortByOption: SortBy<AnnotationCategorySetSortByOptions>
  ) => void;
  annotationCategorySetCodes: string[];
  annotationCategorySetNames: string[];
}
export const generateGridFilterLink = ({
  setName,
  setCode,
  module,
}: {
  setName?: string;
  setCode?: string;
  module?: string;
}): string => {
  const filterModel: Record<string, { values: string[]; filterType: 'set' }> =
    {};

  if (setName) {
    filterModel.setName = {
      values: [setName],
      filterType: 'set',
    };
  }

  if (setCode) {
    filterModel.setCode = {
      values: [setCode],
      filterType: 'set',
    };
  }

  if (module) {
    filterModel.setModule = {
      values: [module],
      filterType: 'set',
    };
  }

  const gridState = {
    filter: {
      filterModel,
    },
  };

  const base = `categories`;
  const gridStateParam = encodeURIComponent(JSON.stringify(gridState));

  return `${base}?gridState=${gridStateParam}`;
};

const NAME_COLUMN_LEFT_PADDING = 42;

export const AnnotationCategorySetsTable = ({
  loading,
  annotationCategorySets,
  sortBy,
  setSortByOptions,
  annotationCategorySetCodes,
  annotationCategorySetNames,
}: AnnotationCategorySetsTableProps): ReactElement => {
  const columns: TableHeaderType<AnnotationCategorySet>[] = useMemo(
    () => [
      {
        id: 'name',
        label: 'Annotation Set',
        renderCell: (row) => (
          <div style={{ paddingLeft: `${NAME_COLUMN_LEFT_PADDING}px` }}>
            {row.name}
          </div>
        ),
        align: 'left',
      },
      {
        id: 'code',
        label: 'Set Identifier',
        minWidth: '20%',
        renderCell: (row) => row?.code,
        align: 'left',
      },
      {
        id: 'moduleCount',
        withSubRows: true,
        label: 'Modules',
        renderCell: (row) =>
          row?.moduleCount && row.moduleCount > 0 ? (
            row.moduleCount
          ) : (
            <div style={{ padding: '16px' }}>0</div>
          ),
        align: 'left',
        subRowsConfig: {
          data: (row) =>
            row.modules.map((module) => ({
              ...module,
              setName: row.name,
              setCode: row.code,
            })),
          columns: [
            {
              id: 'moduleCount',
              renderCell: (data) => data.moduleName,
            },
            {
              id: 'categoryCount',
              renderCell: ({ setName, setCode, categoryCount, moduleName }) => (
                <$TableLink
                  to={generateGridFilterLink({
                    setName,
                    setCode,
                    module: moduleName,
                  })}
                >
                  {categoryCount}
                </$TableLink>
              ),
            },
          ],
        },
      },
      {
        id: 'categoryCount',
        label: 'Categories',
        renderCell: (row) => (
          <$TableLink
            to={generateGridFilterLink({
              setName: row.name,
              setCode: row.code,
            })}
          >
            {row?.categoryCount ?? '0'}
          </$TableLink>
        ),
        align: 'left',
      },
      {
        id: 'actions',
        label: 'Actions',
        disableSorting: true,
        renderCell: (row) => (
          <AnnotationCategorySetsTableRowActions
            annotationCategorySet={row}
            annotationCategorySetCodes={annotationCategorySetCodes}
            annotationCategorySetNames={annotationCategorySetNames}
          />
        ),
        align: 'left',
      },
    ],
    [annotationCategorySetCodes, annotationCategorySetNames]
  );
  const [columnsState, setColumnsState] = useState<
    TableHeaderType<AnnotationCategorySet>[]
  >([...columns.map((column) => ({ ...column, checked: true }))]);

  useEffect(() => {
    setColumnsState([
      ...columns.map((column) => ({ ...column, checked: true })),
    ]);
  }, [columns, setColumnsState]);

  return (
    <$TableContainer>
      {loading || (!loading && annotationCategorySets.length) ? (
        <TableComponent<AnnotationCategorySet>
          dynamicColumns={false}
          enableSelection={false}
          data={
            loading || !annotationCategorySets ? [] : annotationCategorySets
          }
          columns={columnsState}
          setColumnsState={setColumnsState}
          rowSelector="id"
          sorting={{
            column: sortBy?.column ?? 'name',
            direction: sortBy?.sortDirection ?? 'asc',
          }}
          setSorting={(val) => {
            setSortByOptions({
              column: val?.column as AnnotationCategorySetSortByOptions,
              sortDirection: val?.direction as SortByDirection,
            });
          }}
        />
      ) : null}
      {!loading && annotationCategorySets.length === 0 ? (
        <Placeholder title="No sets & modules found" />
      ) : null}
      {loading && (
        <div data-testid="annotation-category-sets-table-skeleton">
          <TableSkeleton rows={PAGE_SIZE} />
        </div>
      )}
    </$TableContainer>
  );
};
