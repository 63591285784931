import {
  PageLayout,
  VisibleWithScope,
  useSnackbarMutations,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import React, { ReactElement, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UploadSlidesStep, UploadSlidesSteps } from './UploadSlides';

/**
 * Page for admins to upload new Slide files and start the slide onboarding
 * process.
 *
 * NOTE: This is work in progress and not fully functional yet, API endpoints
 * for sending the uploaded files metadata are missing.
 */

type AdminUploadSlidesProps = {
  role: OrganizationRole;
  authToken: string | null;
  apiUrl: string;
  origin: string;
};
export default function AdminUploadSlides({
  role,
  authToken,
  apiUrl,
  origin,
}: AdminUploadSlidesProps): ReactElement {
  const location = useLocation();
  const { addSnackbar } = useSnackbarMutations();

  const files = location.state?.files;
  const csv = location.state?.csv;

  const [step, onStepChanged] = useState<UploadSlidesStep>({
    name: 'calculateChecksum',
    files,
    csv,
    title: 'Calculate checksum',
  });

  if (!Array.isArray(files) || files.length === 0) {
    addSnackbar({
      type: 'warning',
      message:
        'Please select at least one slide file to proceed with the upload process',
    });

    return (
      <Navigate
        to={'/admin/data-onboarding'}
        state={{ shouldOpenDataUploadModal: true }}
      />
    );
  }

  return (
    <PageLayout
      title={step.title}
      description="Please provide information about the slides (you can use the table header to add global values). You may also include a CSV file containing prefilled fields."
    >
      <VisibleWithScope role={role} scope="wsi:upload">
        <UploadSlidesSteps
          origin={origin}
          apiUrl={apiUrl}
          step={step}
          onStepChanged={onStepChanged}
          authToken={authToken}
        />
      </VisibleWithScope>
    </PageLayout>
  );
}
