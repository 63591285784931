import { Organization, OrganizationRole } from '@aignostics/core';
import * as Accordion from '@radix-ui/react-accordion';
import React, { ReactElement } from 'react';
import { AccordionItem } from '../AccordionItem.component';
import { Impersonation } from './Impersonation';
import { OrganizationSelector } from './OrganizationSelector';

export const ProfileSettings = ({
  currentUserRole,
  impersonatedUserEmail,
  organizationUuid,
  organizations,
}: {
  currentUserRole: OrganizationRole;
  impersonatedUserEmail: string | null;
  organizationUuid: string;
  organizations: Organization[];
}): ReactElement => (
  <Accordion.Root
    collapsible
    type="single"
    defaultValue={impersonatedUserEmail !== null ? 'Impersonation' : undefined}
  >
    {organizations.length > 1 ? (
      <AccordionItem value="Organizations" level={2}>
        <OrganizationSelector
          organizations={organizations}
          initialActiveOrganizationUuid={organizationUuid}
        />
      </AccordionItem>
    ) : null}
    {impersonatedUserEmail !== null ||
    currentUserRole.scopes['user:impersonate'] ? (
      <AccordionItem value="Impersonation" level={2}>
        <Impersonation />
      </AccordionItem>
    ) : null}
  </Accordion.Root>
);
