import React, { type ReactElement } from 'react';
import {
  SelectDataFetcher,
  type SelectDataFetcherProps,
} from '../pages/SetFileMetadataStep';
import { SelectDataFetcher as SelectDataFetcherPOC } from '../pages/SlidesMetadataEditor';

// TODO: [FE-5550]: Remove feature flag code
export const QUERY_PARAM_AG_GRID = 'use-ag-grid';
export const QUERY_PARAM_AG_GRID_VALUE = 'enabled';

export function enableAGGridRenderIfRequired(): void {
  if (document.location.search.includes(QUERY_PARAM_AG_GRID)) {
    window.USE_AG_GRID_POC_FOR_SLIDES_METADATA_EDITOR = true;
  }
}

/**
 * Renders conditionally new or old metadata editor based on the presence of the
 * url query param `use-ag-grid`.
 */
export function renderSlidesMetadataEditor(
  props: SelectDataFetcherProps
): ReactElement {
  enableAGGridRenderIfRequired();

  if (window.USE_AG_GRID_POC_FOR_SLIDES_METADATA_EDITOR) {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has(QUERY_PARAM_AG_GRID)) {
      urlParams.set(QUERY_PARAM_AG_GRID, QUERY_PARAM_AG_GRID_VALUE);
    }
    // useSearchParams hook in the component for some reason doesn't work,
    // navigate always redirects back to the batch list :(
    history.replaceState(history.state, '', `?${urlParams.toString()}`);

    return <SelectDataFetcherPOC {...props} />;
  }

  return <SelectDataFetcher {...props} />;
}
