import styled from 'styled-components';

export const $AppSubHeader = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.base};
  color: ${({ theme }) => theme.colors.white};
`;

export const $MenuSidebarToggleContainer = styled.div`
  position: relative;
`;

export const $WarningIconContainer = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spacings['8']}px;
  bottom: 0;
`;
