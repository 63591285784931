import { DiscreteProgressBarTileProps } from '@aignostics/components';
import { OnboardingWsiOnboardProgressStatus } from '../../../graphql/api.types';

const WSI_SUCCESSFUL_STATUSES: OnboardingWsiOnboardProgressStatus[] = [
  'success',
  'success-with-errors',
];

/**
 * a function to check if an onboardProgress array has only successful steps
 * @param onboardingStages an array of DiscreteProgressBarTileProps
 * @returns                a boolean
 */

export const hasOnlySuccessfulOnboardingStages = (
  onboardingStages: DiscreteProgressBarTileProps[]
): boolean => {
  if (!onboardingStages.length) return false;
  return onboardingStages.every((stage) =>
    WSI_SUCCESSFUL_STATUSES.includes(
      stage.status as OnboardingWsiOnboardProgressStatus
    )
  );
};
