import {
  AG_GRID_LICENSE_KEY,
  SnackbarContainer,
  SnackbarProvider,
} from '@aignostics/components';
import {
  OnboardingMetricsProvider,
  UploadDoneNotifier,
  UploadStateProvider,
  UploadWakeAndNavigationLock,
} from '@aignostics/onboarding-ui';
import { CustomThemeProvider } from '@aignostics/theme';
import { Theme } from '@radix-ui/themes';
import { LicenseManager } from 'ag-grid-enterprise';
import queryString from 'query-string';
import React, { ReactElement } from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { initSentry } from '../../sentry';
import { router } from '../__Navigation/Routes';
import { ErrorBoundary } from '../__Pages/ErrorBoundary';
import { RevokedAccessModalWrapper } from '../__Pages/Login/RevokedAccessModalWrapper';
import { AppHead } from './AppHead.component';
import { AuthProvider } from './AuthProvider';
import { AppConfigProvider } from './ConfigProvider';
import { ImpersonationProvider } from './ImpersonationProvider';
import { TrackingProvider } from './TrackingProvider';
import { appConfig } from './appConfig';
import { useNetworkStatusNotification } from './useNetworkStatusNotification';

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);
/**
 * Root react app element to
 * @returns
 */
const App = (): ReactElement => {
  initSentry(appConfig.sentry.dsn, appConfig.buildEnv);

  return (
    <Theme accentColor="violet" grayColor="gray" radius="small">
      <CustomThemeProvider theme={appConfig.theme}>
        <QueryParamProvider
          options={{
            searchStringToObject: queryString.parse,
            objectToSearchString: queryString.stringify,
          }}
        >
          <ErrorBoundary>
            <AppConfigProvider config={appConfig}>
              <SnackbarProvider>
                <NetworkStatusProvider />
                <UploadStateProvider>
                  <OnboardingMetricsProvider>
                    <UploadWakeAndNavigationLock />
                    <AppHead appConfig={appConfig} />
                    <UploadDoneNotifier />
                    <ImpersonationProvider>
                      <AuthProvider>
                        <SnackbarContainer />
                        <RevokedAccessModalWrapper />
                        <TrackingProvider>
                          <RouterProvider router={router} />
                        </TrackingProvider>
                      </AuthProvider>
                    </ImpersonationProvider>
                  </OnboardingMetricsProvider>
                </UploadStateProvider>
              </SnackbarProvider>
            </AppConfigProvider>
          </ErrorBoundary>
        </QueryParamProvider>
      </CustomThemeProvider>
      <div id="modal-root" style={{ position: 'relative', zIndex: 1 }} />
      <div id="menu-root" />
    </Theme>
  );
};

function NetworkStatusProvider(): null {
  useNetworkStatusNotification();
  return null;
}

export default App;
