import {
  Button,
  Icon,
  Input,
  Modal,
  SelectSingle,
} from '@aignostics/components';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { Staining } from '../../../types';
import { proposeMatchingStainings } from '../Form';
import type { StainingsMismatchError } from '../Form/form.state.types';
import {
  $ButtonsWrapper,
  $ContentWrapper,
  $ModalSubTitle,
  $ModalTitle,
  $StainingsMismatchHeader,
  $StainingsMismatchRow,
} from './StainingsMismatchModal.styles';

interface StainingsMismatchModalArgs<T extends StainingsMismatchError> {
  isOpen: boolean;
  onCloseModal: () => void;
  stainingsMismatchErrors: T[];
  updatedStainingsValues: T[];
  stainings: Staining[];
  setUpdatedStainingsValues: React.Dispatch<React.SetStateAction<Array<T>>>;
  onUpdateStainings: () => void;
}

const getUniqueMismatches = (
  stainings: StainingsMismatchError[]
): Record<string, Array<number>> => {
  return stainings.reduce<Record<string, Array<number>>>(
    (obj, slide, index) => {
      if (!slide.value) return obj;

      obj[slide.value] = [...(obj[slide.value] ?? []), index];

      return obj;
    },
    {}
  );
};

const StainingsMismatchModal = <T extends StainingsMismatchError>({
  isOpen,
  onCloseModal,
  stainingsMismatchErrors,
  updatedStainingsValues,
  stainings,
  setUpdatedStainingsValues,
  onUpdateStainings,
}: StainingsMismatchModalArgs<T>): ReactElement => {
  const uniqueMismatches = useMemo(
    () => getUniqueMismatches(stainingsMismatchErrors),
    [stainingsMismatchErrors]
  );

  useEffect(() => {
    const suggestions = proposeMatchingStainings(
      stainings,
      Object.keys(uniqueMismatches)
    );

    setUpdatedStainingsValues((prev) => {
      const newValues = [...prev];

      Object.values(uniqueMismatches).forEach((slides, index) => {
        slides.forEach((slide) => {
          newValues[slide].value = suggestions[index];
        });
      });

      return newValues;
    });
  }, [setUpdatedStainingsValues, stainings, uniqueMismatches]);

  return (
    <Modal
      isVisible={isOpen}
      onClose={onCloseModal}
      shouldCloseOnEscKey
      hasCloseButton
      size="auto"
    >
      <$ContentWrapper>
        <$ModalTitle>Staining names not matching</$ModalTitle>
        <$ModalSubTitle>
          The following staining names on your file do not match our system.
          Choose the equivalent name on our system.
        </$ModalSubTitle>

        <$StainingsMismatchHeader>
          <div>Staining name</div>
          <div>Choose replacement</div>
        </$StainingsMismatchHeader>

        {Object.keys(uniqueMismatches).map((key) => {
          const slides = uniqueMismatches[key];
          return (
            <$StainingsMismatchRow key={key}>
              <Input
                style={{ margin: '8px 0' }}
                value={key}
                id={key}
                disabled
              />
              <Icon
                icon="ArrowLeft"
                size="input"
                color="dark"
                style={{ transform: 'rotate(180deg)', margin: '0 22px' }}
              />

              <SelectSingle
                id={`${key}_select`}
                onChange={(newValue) => {
                  setUpdatedStainingsValues((prev) => {
                    const newValues = [...prev];

                    slides.forEach((slide) => {
                      newValues[slide].value = newValue?.label ?? null;
                    });

                    return newValues;
                  });
                }}
                value={updatedStainingsValues[slides[0]]?.value}
                options={stainings.map(({ name }) => ({
                  value: name,
                  label: name,
                }))}
              />
            </$StainingsMismatchRow>
          );
        })}
        <$ButtonsWrapper>
          <Button
            style={{ margin: '4px' }}
            variant="primaryOutline"
            small
            onClick={onCloseModal}
          >
            Complete later
          </Button>
          <Button
            style={{ margin: '4px' }}
            variant="primary"
            small
            onClick={() => {
              onUpdateStainings();
              onCloseModal();
            }}
          >
            Replace Names
          </Button>
        </$ButtonsWrapper>
      </$ContentWrapper>
    </Modal>
  );
};

export default StainingsMismatchModal;
