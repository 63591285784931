import { MultiChannelFormRowErrors } from '../Form';

export const hasMultiChannelErrors = (
  fieldIndex: number,
  slidesErrors?: MultiChannelFormRowErrors[]
): boolean =>
  Boolean(
    slidesErrors?.[fieldIndex]?.channels?.some(
      (channel) => channel !== undefined
    )
  );
