import { gql } from '@apollo/client';

export const FETCH_ANNOTATION_CATEGORIES_FILTER_DATA = gql`
  query GET_ANNOTATION_CATEGORIES_FILTER_DATA(
    $page: Int
    $pageSize: Int
    $sortBy: sortByAnnotationCategorySetsValues
    $sortDirection: sortDirection
  ) {
    annotationCategorySets(
      page: $page
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      nodes {
        id
        code
        name
        moduleCount
        categoryCount
        modules {
          moduleId
          moduleName
          categoryCount
        }
      }
    }
  }
`;

export const FETCH_SET_CODES = gql`
  query GET_SET_CODES {
    annotationCategorySets {
      nodes {
        code
      }
    }
  }
`;

export const FETCH_SET_NAMES = gql`
  query GET_SET_NAMES {
    annotationCategorySets {
      nodes {
        name
      }
    }
  }
`;

export const FETCH_MODULE_NAMES = gql`
  query GET_MODULE_NAMES {
    annotationCategorySets {
      nodes {
        modules {
          moduleName
        }
      }
    }
  }
`;
