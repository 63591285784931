import { HStack } from '@aignostics/components';
import { ChevronDown } from '@aignostics/icons';
import * as Accordion from '@radix-ui/react-accordion';
import React, { ReactElement } from 'react';
import {
  $AccordionContent,
  $AccordionItem,
  $AccordionTrigger,
} from './AccordionItem.styles';

export const AccordionItem = ({
  value,
  children,
  level,
}: {
  value: string;
  children: ReactElement;
  level?: number;
}): ReactElement => {
  return (
    <$AccordionItem value={value} level={level ?? 1}>
      <Accordion.Header>
        <$AccordionTrigger>
          <HStack
            justifyContent="space-between"
            alignItems="center"
            style={{ width: '100%' }}
          >
            <span>{value}</span>
            <ChevronDown className="AccordionChevron" />
          </HStack>
        </$AccordionTrigger>
      </Accordion.Header>
      <$AccordionContent>{children}</$AccordionContent>
    </$AccordionItem>
  );
};
