import {
  gql,
  useLazyQuery,
  type LazyQueryExecFunction,
  type OperationVariables,
} from '@apollo/client';
import { useState } from 'react';
import type {
  MultiChannelFormRow,
  PatientCaseIdPair,
  SingleChannelFormRow,
  TissueBlockCaseIdTriple,
} from './form.state.types';
import { getUniquePatientCaseIdPairs } from './getUniquePatientCaseIdPairs';
import { getUniqueTissueBlockCaseIdTriples } from './getUniqueTissueBlockCaseIdTriples';

export const FETCH_MATCHING_WSIS = gql`
  query FETCH_MATCHING_WSIS(
    $association: String!
    $blockCaseIdTissuesTriples: [BlockCaseIdTissueTriple!]!
    $subqueries: [MatchingWsisArgs]!
  ) {
    matchingWsis(association: $association, subqueries: $subqueries) {
      matchingWsisCounts
      messages {
        message
      }
    }
    matchingWsisByBlockTissue(
      association: $association
      blockCaseIdTissuesTriples: $blockCaseIdTissuesTriples
    ) {
      messages {
        message
      }
    }
  }
`;

export type MatchingWsisGroup = PatientCaseIdPair & {
  message: string;
  matchingWsisCount: number;
};

export type MatchingWsisByBlockTissue = TissueBlockCaseIdTriple & {
  message: string | null;
};

type UseMatchingWsisResponse = {
  matchingWsis: {
    matchingWsisCounts: number[];
    messages: { message: string }[];
  };
  matchingWsisByBlockTissue: {
    messages: { message: string }[];
  };
};

export const getMatchingWsisTissueCaseIdAndBlocks = (
  slides: Array<SingleChannelFormRow | MultiChannelFormRow>
): TissueBlockCaseIdTriple[] => getUniqueTissueBlockCaseIdTriples(slides);

export const useMatchingWsis = (): {
  matchingWsisGroups: MatchingWsisGroup[];
  matchingWsisByBlockTissue: MatchingWsisByBlockTissue[];
  handleCompletedRefetchMatchingWsisGroups: (
    slides: Array<SingleChannelFormRow | MultiChannelFormRow>,
    data: UseMatchingWsisResponse
  ) => void;
  refetchMatchingWsis: LazyQueryExecFunction<
    UseMatchingWsisResponse,
    OperationVariables
  >;
  refetchMatchingWsisLoadingStatus: boolean;
} => {
  const [matchingWsisGroups, setMatchingWsisGroups] = useState<
    MatchingWsisGroup[]
  >([]);
  const [matchingWsisByBlockTissue, setMatchingWsisByBlockTissue] = useState<
    MatchingWsisByBlockTissue[]
  >([]);
  const [refetchMatchingWsis, { loading: refetchMatchingWsisLoadingStatus }] =
    useLazyQuery<UseMatchingWsisResponse>(FETCH_MATCHING_WSIS, {
      fetchPolicy: 'cache-first',
    });

  const handleCompletedRefetchMatchingWsisGroups = (
    slides: Array<SingleChannelFormRow | MultiChannelFormRow>,
    data: UseMatchingWsisResponse
  ) => {
    if (data?.matchingWsis) {
      const uniquePatientCaseIdPairs = getUniquePatientCaseIdPairs(slides);

      setMatchingWsisGroups(
        uniquePatientCaseIdPairs.map((uniquePatientCaseIdPair, index) => ({
          ...uniquePatientCaseIdPair,
          matchingWsisCount: data.matchingWsis.matchingWsisCounts[index] ?? 0,
          message: data.matchingWsis.messages[index]?.message,
        }))
      );
    }

    if (data?.matchingWsisByBlockTissue) {
      const matchingWsisTissueCaseIdAndBlocks =
        getMatchingWsisTissueCaseIdAndBlocks(slides);

      setMatchingWsisByBlockTissue(
        matchingWsisTissueCaseIdAndBlocks.map(
          (matchingWsisTissueCaseIdAndBlock, index) => ({
            ...matchingWsisTissueCaseIdAndBlock,
            message: data.matchingWsisByBlockTissue.messages[index]?.message,
          })
        )
      );
    }
  };

  return {
    matchingWsisGroups,
    matchingWsisByBlockTissue,
    handleCompletedRefetchMatchingWsisGroups,
    refetchMatchingWsis,
    refetchMatchingWsisLoadingStatus,
  };
};
