import { MultiChannelFormRow, SingleChannelFormRow } from './form.state.types';
import { getRowFileName } from './getRowFileName';

/**
 * a function to sort slides by name alphabetically
 * @param a SingleChannelSlideFormRow | MultiChannelGroupedFormRows
 * @param b SingleChannelSlideFormRow | MultiChannelGroupedFormRows
 * @returns a number
 */

export const sortSlidesAlphabeticallyByFileName = (
  a: SingleChannelFormRow | MultiChannelFormRow,
  b: SingleChannelFormRow | MultiChannelFormRow
): number => {
  const stringA = getRowFileName(a);
  const stringB = getRowFileName(b);
  if (!stringA || !stringB) return 0;
  return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
};
