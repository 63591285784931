import { OnboardingBatchStatus } from '../../graphql/api.types';

export const ONBOARDING_BATCH_SUCCESSFULLY_COMPLETED_STATUSES: OnboardingBatchStatus[] =
  ['completed', 'completed-with-errors'];

export const ONBOARDING_BATCH_COMPLETED_STATUSES: OnboardingBatchStatus[] = [
  ...ONBOARDING_BATCH_SUCCESSFULLY_COMPLETED_STATUSES,
  'failed',
];

export const ONBOARDING_BATCH_REMOVABLE_STATUSES: OnboardingBatchStatus[] = [
  'uploading',
  'failed',
];

export const ONBOARDING_BATCH_EDITABLE_STATUSES: OnboardingBatchStatus[] = [
  'pending',
  'uploading',
];
