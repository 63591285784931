import { useState } from 'react';
import useDebounce from './useDebounce';
import useEventListener from './useEventListener';

export interface Viewport extends Record<string, boolean | number> {
  width: number;
  height: number;
}

function getViewport(breakpoints: Record<string, number>): Viewport {
  const viewport: Viewport = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  if (breakpoints) {
    Object.entries(breakpoints).forEach(([breakpointKey, value]) => {
      viewport[breakpointKey] = viewport.width >= value;
    });
  }

  return viewport;
}

export default function useViewport(
  breakpoints: Record<string, number>
): Viewport {
  const [viewport, setViewport] = useState(getViewport(breakpoints));

  const debouncedViewport = useDebounce(viewport, 200);

  useEventListener('resize', () => {
    setViewport(getViewport(breakpoints));
  });

  return debouncedViewport;
}
