import { Loader, Section, useSnackbarMutations } from '@aignostics/components';
import { useQuery } from '@apollo/client';
import React, { useCallback, useMemo, type ReactElement } from 'react';
import useUserAssignedAssociation from '../../hooks/useUserAssignedAssociation';
import {
  FETCH_ONBOARDING_BATCH_UPDATE_SUGGESTIONS,
  selectDataQuery,
  type BatchNamesData,
  type SelectData,
} from './Form';
import {
  SNACKBAR_TIMEOUT,
  SetFileMetadataStep,
  type SetFileMetadataStepProps,
} from './SetFileMetadataStep.component';
import { useFilteredSelectData } from './hooks/useFilteredSelectData';

export type SelectDataFetcherProps = Omit<
  SetFileMetadataStepProps,
  'userAssignedAssociation' | 'onboardingBatchNames' | 'selectData'
>;

/**
 * Wraps <SetFileMetadataStep />, performs initial data fetching for the selects
 */
export const SelectDataFetcher = (
  props: SelectDataFetcherProps
): ReactElement | null => {
  const { addSnackbar } = useSnackbarMutations();
  const { userAssignedAssociation, userAssignedAssociationLoading } =
    useUserAssignedAssociation();

  const addErrorSnackbar = useCallback(() => {
    addSnackbar({
      message: 'An error has occurred loading stainings and tissues',
      type: 'error',
      closesAfter: SNACKBAR_TIMEOUT,
    });
  }, [addSnackbar]);

  const selectDataQueryResult = useFilteredSelectData(
    useQuery<SelectData>(selectDataQuery, {
      onError: addErrorSnackbar,
      onCompleted: (data) => {
        if (!data) {
          addErrorSnackbar();
        }
      },
    })
  );

  const { data: onboardingBatchesData, loading: onboardingBatchesLoading } =
    useQuery<BatchNamesData>(FETCH_ONBOARDING_BATCH_UPDATE_SUGGESTIONS, {
      skip: Boolean(props.onboardingBatch),
    });

  const onboardingBatchNames = useMemo(
    () => onboardingBatchesData?.onboardingBatchNames || [],
    [onboardingBatchesData?.onboardingBatchNames]
  );

  if (
    selectDataQueryResult.loading ||
    userAssignedAssociationLoading ||
    onboardingBatchesLoading
  ) {
    return (
      <Section>
        <Loader />
      </Section>
    );
  }

  if (selectDataQueryResult.error || selectDataQueryResult.data === undefined) {
    return null;
  }

  return (
    <SetFileMetadataStep
      {...props}
      userAssignedAssociation={userAssignedAssociation}
      onboardingBatchNames={onboardingBatchNames}
      selectData={selectDataQueryResult.data}
    />
  );
};
