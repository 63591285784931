import {
  Icon,
  PaginationInfo,
  Placeholder,
  Section,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableSkeleton,
  Tooltip,
  useDisclosure,
} from '@aignostics/components';
import { OrganizationRole, User } from '@aignostics/core';
import { pluralize } from '@aignostics/utils';
import { format } from 'date-fns';
import React, { ReactElement, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FetchedOnboardingBatchItem } from '../../graphql/queries/FETCH_ONBOARDING_BATCHES';
import TransferCloudSlidesModal from '../../pages/TransferCloudSlides/TransferCloudSlidesModal.component';
import { UploadSlidesModal } from '../../pages/UploadSlides';
import type { Association } from '../../types';
import { capitalize } from '../../utils/capitalize';
import { AssignBatchModal } from './AssignBatchModal/AssignBatchModal.component';
import { CSVTemplateDownloadButton } from './CSVTemplateDownloadButton.component';
import OnboardingBatchActionButton from './OnboardingBatch.actionButton.component';
import OnboardingBatchStatusToolTip from './OnboardingBatches.statusTooltip';
import {
  $OnboardingBatchesLoadingWrapper,
  $OnboardingSlidesButtonsContainer,
  $OnboardingStatusCompletedWarningIcon,
  $StatusMessage,
  $UploadedRatioContainer,
} from './OnboardingBatches.styles';
import { mapStatusToMessage } from './OnboardingBatches.utils';
import { OnboardingTutorialModal } from './OnboardingTutorial/OnboardingTutorial.component';
import TransferCloudSlidesButton from './TransferCloudSlidesButton.component';
import UploadSlidesButton from './UploadSlidesButton.component';

interface OnboardingBatchesProps {
  data?: FetchedOnboardingBatchItem[];
  userAssignedAssociation: Pick<Association, 'name'> | null;
  totalBatchesCount?: number;
  page: number;
  pageSize: number;
  isLoading: boolean;
  externalBucket: string | null;
  role: OrganizationRole;
  currentUser: User;
}

export const OnboardingBatches = ({
  data = [],
  userAssignedAssociation,
  totalBatchesCount = 0,
  page,
  pageSize,
  isLoading,
  externalBucket,
  role,
  currentUser,
}: OnboardingBatchesProps): ReactElement => {
  const location = useLocation();
  const uploadSlidesModal = useDisclosure(
    location.state?.shouldOpenDataUploadModal === true
  );
  const selectSlidesModal = useDisclosure(
    location.state?.shouldOpenDataSelectModal === true
  );

  const showTransferCloudSlidesButton = externalBucket !== null;
  const assignModalData = useDisclosure<string>();

  const TABLE_HEADERS = [
    { name: 'Batch name', width: '20ch' },
    ...(userAssignedAssociation === null
      ? [{ name: 'Association', width: '10ch' }]
      : []),
    { name: 'Uploading date', width: '15ch' },
    { name: 'Files', width: '5ch' },
    { name: 'Uploaded by', width: '15ch' },
    { name: 'Status', width: '15ch' },
    { name: 'Actions', width: '15ch' },
  ];

  const onboardingBatchesData = useMemo(
    () =>
      data.map(
        ({
          id,
          batchName,
          associationName,
          createdAt,
          totalSlideFiles,
          createdBy,
          status,
        }) => ({
          id,
          batchName: <Link to={`batch/${id}`}>{batchName}</Link>,
          associationName: capitalize(associationName),
          createdAt: format(new Date(createdAt), 'yyyy-MM-dd HH:mm'),
          uploadedFilesRatio: (
            <$UploadedRatioContainer>
              {totalSlideFiles}
              <Icon icon="Image" size="small" />
            </$UploadedRatioContainer>
          ),
          createdBy: createdBy
            ? createdBy.name || createdBy.email
            : 'Unknown User',
          status,
        })
      ),
    [data]
  );

  return (
    <Section>
      <$OnboardingSlidesButtonsContainer>
        <div data-testid="heading-section">
          <PaginationInfo
            totalCount={totalBatchesCount}
            currentPage={page}
            itemsPerPage={pageSize}
            itemType={pluralize('Batch', totalBatchesCount, 'Batches')}
          />
        </div>
        <div>
          <CSVTemplateDownloadButton />
          {showTransferCloudSlidesButton && (
            <TransferCloudSlidesButton onClick={selectSlidesModal.open} />
          )}
          <UploadSlidesButton onClick={uploadSlidesModal.open} />
        </div>
      </$OnboardingSlidesButtonsContainer>
      {onboardingBatchesData.length === 0 && !isLoading ? (
        <Placeholder
          title={`No batches`}
          description={`You don't have batches`}
        />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEADERS.map((headerElement, index) => (
                <TableHeader key={index} width={headerElement.width}>
                  {headerElement.name === 'Status' ? (
                    <div>
                      {headerElement.name}
                      <OnboardingBatchStatusToolTip />
                    </div>
                  ) : (
                    headerElement.name
                  )}
                </TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {onboardingBatchesData.map((batch) => (
              <TableRow key={batch.id} aria-label="Batch Row">
                <TableCell>{batch.batchName}</TableCell>
                {userAssignedAssociation === null && (
                  <TableCell>{batch.associationName}</TableCell>
                )}
                <TableCell>{batch.createdAt}</TableCell>
                <TableCell>{batch.uploadedFilesRatio}</TableCell>
                <TableCell>{batch.createdBy}</TableCell>
                <TableCell>
                  <$StatusMessage
                    aria-label="Batch Status"
                    color={mapStatusToMessage[batch.status].color}
                  >
                    {mapStatusToMessage[batch.status].message}
                    {batch.status === 'failed' && (
                      <Tooltip text={'All slides failed processing'}>
                        {(tooltipProps) => (
                          <span {...tooltipProps}>
                            <$OnboardingStatusCompletedWarningIcon
                              icon="AlertCircle"
                              size="small"
                              color="error"
                            />
                          </span>
                        )}
                      </Tooltip>
                    )}
                  </$StatusMessage>
                </TableCell>
                <TableCell>
                  <OnboardingBatchActionButton
                    ariaLabel="Batch Action"
                    batchId={batch.id}
                    batchStatus={batch.status}
                    onAssignToProject={assignModalData.open}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {isLoading && (
        <$OnboardingBatchesLoadingWrapper>
          <TableSkeleton rows={pageSize} />
        </$OnboardingBatchesLoadingWrapper>
      )}
      <AssignBatchModal
        isOpen={assignModalData.isOpen}
        batchId={assignModalData.data}
        onCloseModal={assignModalData.close}
        role={role}
        currentUser={currentUser}
      />
      <OnboardingTutorialModal />
      <UploadSlidesModal
        isVisible={uploadSlidesModal.isOpen}
        onClose={uploadSlidesModal.close}
      />
      <TransferCloudSlidesModal
        isVisible={selectSlidesModal.isOpen}
        onClose={selectSlidesModal.close}
        externalBucket={externalBucket}
      />
    </Section>
  );
};
