import { gql } from '@apollo/client';

// Base fragment containing common fields
const WSI_BASE_FIELDS = gql`
  fragment WsiBaseFields on WSI {
    id
    originalName: name(type: original)
    width
    height
    maxZoom
    objectivePower
    mppx
    mppy
    block
    section
    disease
    samplePreparation
    sampleType
    staining
    scanner {
      id
      vendor
      model
    }
    bounds {
      type
      geometry
      properties
    }
    tissue
    staining
    patientExternalId
    case {
      id
      name
    }
    scanner {
      id
      vendor
      model
    }
    bitDepth
  }
`;

// Admin-only fields fragment
const WSI_ADMIN_FIELDS = gql`
  fragment WsiAdminFields on WSI {
    path
    batchName
  }
`;

// Query with conditional fields based on role
export const FETCH_WSI_METADATA = gql`
  query GET_WSI_INFO_MODAL_DATA(
    $wsiId: ID!
    $subProjectId: ID
    $isViewer: Boolean!
  ) {
    wsi(id: $wsiId, subProjectId: $subProjectId) {
      ...WsiBaseFields
      name(subProjectId: $subProjectId)
      ...WsiAdminFields @skip(if: $isViewer)
    }
  }
  ${WSI_BASE_FIELDS}
  ${WSI_ADMIN_FIELDS}
`;

// Admin query that always includes admin fields
export const FETCH_WSI_ADMIN_METADATA = gql`
  query GET_WSI_ADMIN_INFO_MODAL_DATA($wsiId: ID!) {
    wsi(id: $wsiId) {
      ...WsiBaseFields
      name
      ...WsiAdminFields
    }
  }
  ${WSI_BASE_FIELDS}
  ${WSI_ADMIN_FIELDS}
`;
