import type { CustomCellRendererProps } from 'ag-grid-react';
import { useEffect, useRef } from 'react';
import type { GridData, MetadataGridContext } from '../MetadataGrid.types';

/**
 * This component rendered only during edit attempt of mif channel with
 * an invalid value. In a response to this action, during render it will trigger
 * staining mismatch dialog to appear, and invoke grid's method to stop editing.
 */
export const CustomCellInvalidMIFChannelEditor = ({
  context,
  api,
}: CustomCellRendererProps<GridData, never, MetadataGridContext>): null => {
  const triggeredDialog = useRef(false);

  // side effect of interaction with grid's API shouldn't be done in the render
  useEffect(() => {
    if (!triggeredDialog.current) {
      triggeredDialog.current = true;
      context.triggerStainingMismatchDialog();
      api.stopEditing();
    }
  }, [api, context]);

  return null;
};
