import { Loader, Section } from '@aignostics/components';
import {
  init as initWsiMetadataParser,
  type MetadataParser,
} from '@aignostics/extract-wsi-metadata';
import React, { useEffect, useRef, type ReactElement } from 'react';
import { crc32cChecksum } from '../../../utils/crc32cChecksum';
import { mapHERGBtoHE } from '../../TransferCloudSlides/utils/mapHERGBtoHE';
import { BlobFileWithChecksumAndMetadata } from '../UploadStateProvider';

interface CalculateChecksumStepProps {
  files: File[];
  onCalculatedChecksum: (
    uploadedFiles: BlobFileWithChecksumAndMetadata[]
  ) => void;
  origin: string;
}

export const CalculateChecksumStep = ({
  files,
  onCalculatedChecksum,
  origin,
}: CalculateChecksumStepProps): ReactElement => {
  const wsiMetadataParser = useRef<Promise<MetadataParser>>();

  if (!wsiMetadataParser.current) {
    wsiMetadataParser.current = initWsiMetadataParser(
      `${origin}/assets/extract-wsi-metadata/webworker.js`,
      `${origin}/assets/extract-wsi-metadata/pyodide/`
    );
  }

  useEffect(() => {
    let metadataParser: MetadataParser;

    void (async function () {
      metadataParser = await wsiMetadataParser.current!;
      const [metadata, ...checksums] = await Promise.all([
        metadataParser.parse(files),
        ...files.map((file) => crc32cChecksum(file)),
        // for debugging/development purposes use:
        // ...files.map(() => 'checksum'),
      ]);
      const mappedMetadata = mapHERGBtoHE(metadata);
      onCalculatedChecksum(
        files.map((file, index) => ({
          file,
          checksum: checksums[index],
          metadata: mappedMetadata[index],
        }))
      );
    })();

    return () => {
      metadataParser?.dispose();
    };
  }, [files, onCalculatedChecksum]);

  return (
    <Section>
      <div style={{ display: 'flex' }}>
        Calculating, please wait...
        <Loader />
      </div>
    </Section>
  );
};
