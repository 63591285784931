import { WsiSlideMetadata } from '@aignostics/extract-wsi-metadata';
import type { Reducer } from 'react';

export interface BlobFileWithChecksumAndMetadata {
  checksum: string;
  file: File;
  metadata: WsiSlideMetadata;
}

export interface OnboardingBatchUploadLink {
  url: string;
  bucketName: string;
  objectPath: string;
}

// file upload progress
export const FILE_PROGRESS_STATE = {
  WAITING_TO_START: 'waiting-to-start',
  UPLOADING: 'uploading',
  DONE: 'done',
  ERROR: 'error',
} as const;
type UploadFileProgressWaitingToStart = {
  state: typeof FILE_PROGRESS_STATE.WAITING_TO_START;
  progress: 0;
  fileSize: number;
};
type UploadFileProgressUploading = {
  state: typeof FILE_PROGRESS_STATE.UPLOADING;
  progress: number;
  fileSize: number;
};
type UploadFileProgressDone = {
  state: typeof FILE_PROGRESS_STATE.DONE;
  progress: number;
  fileSize: number;
};
export type UploadFileProgressError = {
  state: typeof FILE_PROGRESS_STATE.ERROR;
  progress: number;
  fileSize: number;
  error: string;
};
export type UploadFileProgress =
  | UploadFileProgressWaitingToStart
  | UploadFileProgressUploading
  | UploadFileProgressDone
  | UploadFileProgressError;

// state
export const UPLOADING_FILE_STATUS = {
  IDLE: 'idle',
  WAITING_FOR_UPLOAD: 'waiting-for-upload',
  UPLOADING: 'uploading',
  ABORTED: 'aborted',
} as const;
type UploadFilesStateIdle = {
  status: typeof UPLOADING_FILE_STATUS.IDLE;
};
type UploadFilesStateCommon = {
  batchId: string;
  batchName: string;
  fileSlides: BlobFileWithChecksumAndMetadata[];
  uploadLinks: OnboardingBatchUploadLink[];
  progress: UploadFileProgress[];
  mapFilenameToIndex: Record<string, number>;
  wsiUuidsToFileIndex: Map<string, number>;
  abortController: AbortController;
};
type UploadFilesStateWaitingForUpload = {
  status: typeof UPLOADING_FILE_STATUS.WAITING_FOR_UPLOAD;
} & UploadFilesStateCommon;
export type UploadFilesStateUploading = {
  status: typeof UPLOADING_FILE_STATUS.UPLOADING;
} & UploadFilesStateCommon;
type UploadFileStateAborted = {
  status: typeof UPLOADING_FILE_STATUS.ABORTED;
};
export type UploadFilesState =
  | UploadFilesStateIdle
  | UploadFilesStateWaitingForUpload
  | UploadFilesStateUploading
  | UploadFileStateAborted;

// reducer's actions
export const ONBOARDING_FILES_UPLOAD_ACTION = {
  INIT: 'init',
  UPLOADING: 'uploading',
  ERROR: 'error',
  DONE: 'done',
  RESET: 'reset',
  ABORT: 'abort',
} as const;
type UploadFilesActionInit = {
  type: typeof ONBOARDING_FILES_UPLOAD_ACTION.INIT;
  batchId: string;
  batchName: string;
  fileSlides: BlobFileWithChecksumAndMetadata[];
  wsiUuidsToFileIndex: Map<string, number>;
  uploadLinks: OnboardingBatchUploadLink[];
};
type UploadFilesActionUploading = {
  type: typeof ONBOARDING_FILES_UPLOAD_ACTION.UPLOADING;
  filename: string;
  uploaded: number;
};
type UploadFilesActionError = {
  type: typeof ONBOARDING_FILES_UPLOAD_ACTION.ERROR;
  filename: string;
  error: string;
};
type UploadFilesActionDone = {
  type: typeof ONBOARDING_FILES_UPLOAD_ACTION.DONE;
  filename: string;
};
type UploadFilesActionReset = {
  type: typeof ONBOARDING_FILES_UPLOAD_ACTION.RESET;
};
type UploadFilesActionAbort = {
  type: typeof ONBOARDING_FILES_UPLOAD_ACTION.ABORT;
};
export type UploadFilesAction =
  | UploadFilesActionInit
  | UploadFilesActionUploading
  | UploadFilesActionError
  | UploadFilesActionDone
  | UploadFilesActionReset
  | UploadFilesActionAbort;

const ERR_BAD_TRANSITION = 'bad state machine transition';

/*
 * diagram - https://www.figma.com/file/TVFDq8EQ2yDwA2YpIoGJa3
 */
const uploadStateReducer: Reducer<UploadFilesState, UploadFilesAction> = (
  state,
  action
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  switch (action.type) {
    case ONBOARDING_FILES_UPLOAD_ACTION.INIT:
      if (
        state.status !== UPLOADING_FILE_STATUS.IDLE &&
        state.status !== UPLOADING_FILE_STATUS.ABORTED
      ) {
        throw new Error(ERR_BAD_TRANSITION);
      }

      return {
        status: UPLOADING_FILE_STATUS.WAITING_FOR_UPLOAD,
        batchId: action.batchId,
        batchName: action.batchName,
        fileSlides: action.fileSlides,
        uploadLinks: action.uploadLinks,
        wsiUuidsToFileIndex: action.wsiUuidsToFileIndex,
        progress: action.uploadLinks.map((_, index) => ({
          state: FILE_PROGRESS_STATE.WAITING_TO_START,
          progress: 0,
          fileSize: action.fileSlides[index].file.size,
        })),
        mapFilenameToIndex: action.fileSlides.reduce(
          (acc, slide, index) => ({
            ...acc,
            [slide.file.name]: index,
          }),
          {}
        ),
        abortController: new AbortController(),
      };

    case ONBOARDING_FILES_UPLOAD_ACTION.RESET:
      return { status: UPLOADING_FILE_STATUS.IDLE };

    case ONBOARDING_FILES_UPLOAD_ACTION.ABORT:
      if (state.status === UPLOADING_FILE_STATUS.UPLOADING) {
        state.abortController.abort();
      }

      return { ...state, status: UPLOADING_FILE_STATUS.ABORTED };

    case ONBOARDING_FILES_UPLOAD_ACTION.UPLOADING: {
      if (
        state.status !== UPLOADING_FILE_STATUS.WAITING_FOR_UPLOAD &&
        state.status !== UPLOADING_FILE_STATUS.UPLOADING
      ) {
        throw new Error(ERR_BAD_TRANSITION);
      }
      const fileIndex = state.mapFilenameToIndex[action.filename];
      const progress = [...state.progress];
      progress[fileIndex] = {
        ...state.progress[fileIndex],
        state: FILE_PROGRESS_STATE.UPLOADING,
        progress: action.uploaded,
      };

      return {
        ...state,
        status: UPLOADING_FILE_STATUS.UPLOADING,
        progress,
      };
    }

    case ONBOARDING_FILES_UPLOAD_ACTION.ERROR: {
      if (
        state.status !== UPLOADING_FILE_STATUS.UPLOADING &&
        state.status !== UPLOADING_FILE_STATUS.WAITING_FOR_UPLOAD
      ) {
        throw new Error(ERR_BAD_TRANSITION);
      }
      const fileIndex = state.mapFilenameToIndex[action.filename];
      if (
        state.progress[fileIndex].state !== FILE_PROGRESS_STATE.UPLOADING &&
        state.progress[fileIndex].state !==
          FILE_PROGRESS_STATE.WAITING_TO_START &&
        state.progress[fileIndex].state !== FILE_PROGRESS_STATE.ERROR
      ) {
        throw new Error(ERR_BAD_TRANSITION);
      }

      const progress = [...state.progress];
      progress[fileIndex] = {
        ...state.progress[fileIndex],
        state: FILE_PROGRESS_STATE.ERROR,
        error: action.error,
      };
      state.abortController.abort();

      return {
        ...state,
        ...(state.progress[fileIndex].state ===
          FILE_PROGRESS_STATE.WAITING_TO_START &&
        state.status === UPLOADING_FILE_STATUS.WAITING_FOR_UPLOAD
          ? // in case of error happened before any of the wsis' started uploading
            { status: UPLOADING_FILE_STATUS.UPLOADING }
          : {}),
        progress,
      };
    }

    case ONBOARDING_FILES_UPLOAD_ACTION.DONE: {
      if (
        // axios not guarantees emiting upload event, this can be skipped directly to completed
        state.status !== UPLOADING_FILE_STATUS.WAITING_FOR_UPLOAD &&
        state.status !== UPLOADING_FILE_STATUS.UPLOADING
      ) {
        throw new Error(ERR_BAD_TRANSITION);
      }
      const fileIndex = state.mapFilenameToIndex[action.filename];
      const progress = [...state.progress];
      progress[fileIndex] = {
        ...state.progress[fileIndex],
        state: FILE_PROGRESS_STATE.DONE,
      };

      return { ...state, progress };
    }

    // https://basarat.gitbook.io/typescript/type-system/discriminated-unions#switch
    default: {
      const _exhaustiveCheck: never = action;
      return _exhaustiveCheck;
    }
  }
};

export default uploadStateReducer;
