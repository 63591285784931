import { gql } from '@apollo/client';

export const FETCH_ANNOTATION_CATEGORIES = gql`
  query GET_ANNOTATION_CATEGORIES(
    $page: Int
    $pageSize: Int
    $search: String
    $setNames: [String]
    $setCodes: [String]
    $setModules: [String]
    $sortBy: sortByAnnotationCategoriesValues
    $sortDirection: sortDirection
  ) {
    annotationCategories(
      page: $page
      pageSize: $pageSize
      search: $search
      setNames: $setNames
      setCodes: $setCodes
      setModules: $setModules
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      nodes {
        id
        name
        color
        setId
        setName
        setCode
        setModule
      }
      pageInfo {
        totalElements
        totalPages
      }
      collectionAttributes {
        allAnnotationCategoryNames
      }
    }
  }
`;
