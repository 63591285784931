import { useCallback, useState } from 'react';

/**
 * useState abstraction for toggleable booleans
 * @param initialState boolean to be toggled
 * @return             an array with the current state as the first element and a toggle function as the second
 */
const useToggle = (initialState: boolean): [boolean, () => void] => {
  const [state, setState] = useState(initialState);

  const toggleState = useCallback(() => {
    setState((prevState) => !prevState);
  }, []);

  return [state, toggleState];
};

export default useToggle;
