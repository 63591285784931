import { useCallback, useState } from 'react';

/** Simple Cache Object */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Cache: Record<string, any> = {};

/**
 * Caches state during runtime
 * @param key       Cache key
 * @param initValue
 */
function useKeyValueCache<T>(
  key: undefined | string,
  initValue: T
): [T, (value: T) => void] {
  const [, setCache] = useState<Record<string, T>>({});

  const value = (key && Cache[key]) || initValue;
  const setValue = useCallback(
    (_value: T) => {
      if (key) {
        Cache[key] = _value;
        setCache({ ...Cache, [key]: _value });
      }
    },
    [key]
  );

  return [value, setValue];
}

export default useKeyValueCache;
