import { gql } from '@apollo/client';

export const FETCH_ANNOTATION_CATEGORY_NAMES = gql`
  query GET_ANNOTATION_CATEGORY_NAMES {
    annotationCategories {
      collectionAttributes {
        allAnnotationCategoryNames
      }
    }
  }
`;
