import {
  HStack,
  Icon,
  Link,
  VisibleWithScope,
  VStack,
} from '@aignostics/components';
import { useViewport } from '@aignostics/hooks';
import { formatDate } from '@aignostics/utils';
import * as Accordion from '@radix-ui/react-accordion';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import useUserRole from '../../../hooks/useUserRole';
import { buildTimestamp } from '../../../metadata';
import { appConfig } from '../../App/appConfig';
import { useAuthController, useAuthUser } from '../../App/AuthProvider';
import { useImpersonation } from '../../App/ImpersonationProvider';
import { useSelectedOrganizationUuid } from '../Routes/useSelectedOrganizationUuid';
import { AccordionItem } from './AccordionItem.component';
import {
  $FooterContainer,
  $FooterContent,
  $LogoutButton,
  $MenuItem,
  $MenuList,
  $MenuSidebar,
  $UserEmailDisplay,
} from './MenuSidebar.styles';
import { ProfileSettings } from './ProfileSettings/ProfileSettings.component';

interface MenuSidebarProps {
  closeSideNav: () => void;
}

/** MenuSidebar navigation component */
const MenuSidebar = ({ closeSideNav }: MenuSidebarProps): ReactElement => {
  const organizationUuid = useSelectedOrganizationUuid();
  const theme = useTheme();
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);
  const params = useParams();
  const role = useUserRole();
  const currentUser = useAuthUser();
  const currentUserRole = useUserRole();
  const { signOut } = useAuthController();
  const { impersonatedUserEmail } = useImpersonation();

  useEffect(() => {
    /** Store pathname on mount to detect route changes */
    setPathname(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    /** Handle route change – close sidenav except on wsi pages */
    if (pathname !== location.pathname) {
      closeSideNav();
    }
  }, [location.pathname, closeSideNav, pathname]);

  const menuRef = useRef<HTMLElement>(null);

  // Get viewport height
  const { height } = useViewport(theme.breakpoints);

  const footerHeight = 150;

  const organizations = currentUser.roles
    .filter(({ isDisabled }) => !isDisabled)
    .map(({ organization }) => organization);

  const showProfileSettings =
    organizations.length > 1 ||
    impersonatedUserEmail !== null ||
    currentUserRole.scopes['user:impersonate'];

  return (
    <$MenuSidebar
      ref={menuRef}
      initial={{ x: '-100%' }}
      animate={{ x: '0%' }}
      exit={{ x: '-100%' }}
      transition={{
        ease: 'easeInOut',
        x: { duration: 0.3 },
      }}
      style={{
        height: height - theme.spacings.button,
        justifyContent: 'space-between',
        boxShadow: '4px 1px 8px rgba(0, 0, 0, 0.15)',
        padding: ` 0 0 ${footerHeight}px 0`,
        overflow: 'scroll',
      }}
      aria-label="Slide navigation"
    >
      <VStack style={{ width: '100%' }}>
        <VisibleWithScope role={role} scope="admin:access">
          <$MenuList role="list" aria-label="admin-menu">
            <$MenuItem to={`/${params.organizationUuid}/admin/projects`}>
              Projects
            </$MenuItem>
            <VisibleWithScope role={role} scope="user:readAll">
              <$MenuItem to={`/${params.organizationUuid}/admin/users`}>
                Users
              </$MenuItem>
            </VisibleWithScope>
            <$MenuItem to={`/${params.organizationUuid}/admin/data-onboarding`}>
              Data Onboarding
            </$MenuItem>
            <$MenuItem to={`/${params.organizationUuid}/admin/slide-library`}>
              Slide Library
            </$MenuItem>
          </$MenuList>
        </VisibleWithScope>
        <Accordion.Root
          type="single"
          collapsible
          style={{ width: '100%' }}
          defaultValue={
            impersonatedUserEmail !== null ? 'Profile Settings' : undefined
          }
        >
          <VisibleWithScope role={role} scope="admin:access">
            <AccordionItem value="Organization Settings">
              <$MenuList style={{ textIndent: '8px' }}>
                <$MenuItem
                  to={`/${params.organizationUuid}/annotation-management`}
                >
                  <span>Annotation Management</span>
                </$MenuItem>
              </$MenuList>
            </AccordionItem>
          </VisibleWithScope>
          {showProfileSettings ? (
            <AccordionItem value="Profile Settings">
              <ProfileSettings
                currentUserRole={currentUserRole}
                impersonatedUserEmail={impersonatedUserEmail}
                organizations={organizations}
                organizationUuid={organizationUuid}
              />
            </AccordionItem>
          ) : null}
        </Accordion.Root>
      </VStack>

      <$FooterContainer>
        <VStack style={{ width: '100%' }} spacing="base">
          <$LogoutButton variant="ghost" onClick={signOut}>
            <HStack spacing="8">
              <Icon icon="LogOut" />
              <span>Log out</span>
            </HStack>
          </$LogoutButton>
          <HStack
            spacing="8"
            role="section"
            style={{ width: '100%', paddingLeft: `${theme.spacings[16]}px` }}
          >
            <Icon icon="User" />
            <$UserEmailDisplay
              to={`/${organizationUuid}/admin/users/${currentUser.id}`}
            >
              {currentUser.email}
            </$UserEmailDisplay>
          </HStack>
        </VStack>
        <$FooterContent>
          <div>
            <p>
              Built by{' '}
              <Link
                href="https://www.aignostics.com/impressum/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Aignostics
              </Link>
            </p>
            <p>
              {appConfig.version} - {formatDate(buildTimestamp)}
            </p>
          </div>
        </$FooterContent>
      </$FooterContainer>
    </$MenuSidebar>
  );
};

export default MenuSidebar;
