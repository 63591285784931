import {
  Fieldset,
  FinalFormSelectCreatable,
  FinalFormSelectSingle,
  HStack,
  useDisclosure,
} from '@aignostics/components';
import { validateOnboardingBatchName } from '@aignostics/core';
import { useQuery } from '@apollo/client';
import { type FormApi } from 'final-form';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { FETCH_VALIDATE_WSI_UPDATE } from '../../../graphql/queries/FETCH_VALIDATE_WSI_UPDATE';
import type { Association, Species } from '../../../types';
import type {
  BatchCreateForm,
  BatchName,
  MultiChannelFormRow,
  SingleChannelFormRow,
} from '../Form';
import DuplicatesModal from './DuplicatesModal';

export const ERROR_LABEL_BATCH_NAME_FORBIDDEN_CHARACTERS =
  'Batch names cannot contain spaces, &, / or . sign';

interface BatchMetadataFieldsetProps {
  availableAssociations: Pick<Association, 'name'>[];
  userAssignedAssociation: Pick<Association, 'name'> | null;
  availableSpecies: Species[];
  formChange: FormApi<BatchCreateForm, BatchCreateForm>['change'];
  availableOnboardingBatchNames: BatchName[];
  isDisabled?: boolean;
  initialState: BatchCreateForm;
  formSlides: BatchCreateForm['slides'];
  formBatchName: BatchCreateForm['batch_name'];
  onSlideDuplicationAcceptance: (
    filteredSlides: (SingleChannelFormRow | MultiChannelFormRow)[],
    duplicateSlideNames: string[]
  ) => void;
}

export const BatchMetadataFieldset = ({
  availableSpecies,
  availableAssociations,
  availableOnboardingBatchNames,
  userAssignedAssociation,
  formChange,
  isDisabled,
  initialState,
  formSlides,
  formBatchName,
  onSlideDuplicationAcceptance,
}: BatchMetadataFieldsetProps): ReactElement => {
  const processDuplicateFilesModal = useDisclosure<null>();
  const [lockSpecies, setSpeciesLock] = useState<string | undefined>(
    initialState.species ?? undefined
  );
  const [lockAssociation, setAssociationLock] = useState<string | undefined>(
    initialState.association ?? undefined
  );
  const [batchName, setBatchName] = useState<string | undefined>(
    initialState.batch_name
  );
  const [association, setAssociation] = useState(initialState.association);
  const [existingBatch, setExistingBatch] = useState<BatchName | null>(null);

  const { data: validateWsiData } = useQuery(FETCH_VALIDATE_WSI_UPDATE, {
    variables: {
      onboardingBatchName: batchName,
      association: association ?? '',
      newWsiMetaData: formSlides.map((slide) => ({
        checksum: slide.slideFile.checksum,
        size: slide.slideFile.size.toString(),
        filename: slide.slideFile.filename,
      })),
    },
    skip: !batchName?.length || !association?.length,
  });
  const duplicates = validateWsiData?.validateDuplicateWsi?.duplicates;

  const availableOnboardingBatchNameOptions = useMemo(() => {
    return availableOnboardingBatchNames.map(({ batchName }) => ({
      value: batchName,
      label: batchName,
    }));
  }, [availableOnboardingBatchNames]);

  useEffect(() => {
    // checking batchName.length to avoid reopening on Reset form
    if (duplicates?.length > 0 && batchName && batchName.length > 0) {
      processDuplicateFilesModal.open();
    }
    // to avoid reopening on rerender removing processDuplicateFilesModal from deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicates]);

  // sync batchName on UploadSlides/TransferCloud form resetting:
  useEffect(() => {
    if (formBatchName === '') {
      setBatchName(formBatchName);
    }
  }, [formBatchName]);

  const isBatchNameValidationError = Boolean(
    validateOnboardingBatchName(batchName ?? '')
  );
  const batchNameErrorMessage = isBatchNameValidationError
    ? ERROR_LABEL_BATCH_NAME_FORBIDDEN_CHARACTERS
    : '';
  const batchNameExtraMessage =
    !isDisabled && existingBatch
      ? 'You are adding slides to an existing batch'
      : '';

  const onBatchNameChange = (value: string | undefined) => {
    setBatchName(value);
    const existingBatch = availableOnboardingBatchNames.find(
      ({ batchName }) => batchName === value
    );
    if (value && existingBatch) {
      setExistingBatch(existingBatch);
      formChange('association', existingBatch.associationName);
      formChange('species', existingBatch.species);
      setAssociationLock(existingBatch.associationName);
      setSpeciesLock(existingBatch.species);
      setAssociation(existingBatch.associationName);
    } else {
      setExistingBatch(null);
      formChange('association', undefined);
      formChange('species', undefined);
      setAssociationLock(undefined);
      setSpeciesLock(undefined);
      setAssociation(null);
    }
  };

  return (
    <>
      <Fieldset as={HStack} spacing="base" alignItems="start">
        <FinalFormSelectCreatable
          name="batch_name"
          label="Batch Name"
          isClearable={true}
          disabled={isDisabled}
          required
          onChangeListener={onBatchNameChange}
          options={availableOnboardingBatchNameOptions}
          externalAsyncValidateFn={() => !isBatchNameValidationError}
          extraMessage={batchNameErrorMessage || batchNameExtraMessage}
          style={{ flexGrow: 2, width: '330px' }}
        />

        {userAssignedAssociation === null ? (
          <FinalFormSelectSingle
            name="association"
            label="Association Name"
            disabled={lockAssociation !== undefined || isDisabled}
            lockValue={lockAssociation}
            required
            options={availableAssociations.map(({ name }) => ({
              value: name,
              label: name,
            }))}
            onChangeListener={(v) => {
              setAssociation(v ?? null);
            }}
            style={{ flexGrow: 1 }}
          />
        ) : null}

        <FinalFormSelectSingle
          name="species"
          label="Species"
          disabled={lockSpecies !== undefined || isDisabled}
          lockValue={lockSpecies}
          required
          options={availableSpecies.map(({ name }) => ({
            value: name,
            label: name,
          }))}
          style={{ flexGrow: 1 }}
        />
      </Fieldset>
      <DuplicatesModal
        slides={formSlides}
        isOpen={processDuplicateFilesModal.isOpen}
        duplicates={duplicates}
        onClose={() => {
          formChange('batch_name', '');
          onBatchNameChange('');
          processDuplicateFilesModal.close();
        }}
        closeModal={processDuplicateFilesModal.close}
        updateSlides={onSlideDuplicationAcceptance}
      />
    </>
  );
};
