import {
  Button,
  ColorInput,
  FieldValue,
  HStack,
  Input,
  useSnackbarMutations,
  VStack,
} from '@aignostics/components';
import { useQuery } from '@apollo/client';
import React, { ReactElement, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  AnnotationCategory,
  AnnotationCategoryInput,
  AnnotationCategorySet,
  AnnotationCategorySetInput,
  AnnotationCategorySetModuleInput,
} from '../../types';
import {
  capitalize,
  formatAnnotationCategorySetInputValue,
  isNewAnnotationCategorySetCodeValid,
} from '../../utils';
import { AnnotationCategoryFormSetBlock } from './AnnotationCategoryForm.CategorySetBlock.component';
import {
  $AnnotationCategoryColorWrapper,
  $AnnotationCategoryForm,
  $AnnotationCategoryNameWrapper,
} from './AnnotationCategoryForm.component.styles';
import { FETCH_ANNOTATION_CATEGORY_NAMES } from './FETCH_ANNOTATION_CATEGORY_NAMES.queries';
import { FETCH_ANNOTATION_CATEGORY_SETS } from './FETCH_ANNOTATION_CATEGORY_SETS.queries';
import { isAnnotationCategoryValuesChanged } from './isAnnotationCategoryValuesChanged';

export const HEXADECIMAL_PATTERN = /^#[0-9A-F]{6}$/i;

export const CATEGORY_NAME_EXISTS_TEXT = 'This name already exists';

export type AnnotationCategoryFormMode = 'create' | 'edit';
interface AnnotationCategoryFormBaseProps {
  onSubmit: (newAnnotationCategory: AnnotationCategoryInput) => void;
  localAnnotationCategorySets?: AnnotationCategorySet[];
  localAnnotationCategoryNames?: string[];
}

type AnnotationCategoryFormCreateProps = {
  mode: 'create';
};

type AnnotationCategoryFormEditProps = {
  mode: 'edit';
  initialValues: AnnotationCategory;
};

export type AnnotationCategoryFormProps = (
  | AnnotationCategoryFormEditProps
  | AnnotationCategoryFormCreateProps
) &
  AnnotationCategoryFormBaseProps;

const getInitialAnnotationCategorySetInputValue = (
  annotationCategory: AnnotationCategory,
  annotationCategorySets: AnnotationCategorySet[]
): AnnotationCategorySetInput => {
  const { setCode, setModule } = annotationCategory;
  if (!setCode) return { kind: 'unset' };

  const categorySet = annotationCategorySets.find(
    (set) => set.code === setCode
  );

  if (!categorySet) return { kind: 'unset' };

  const { modules } = categorySet;
  const module = setModule
    ? modules.find((module) => module.moduleName === setModule)
    : null;
  return {
    kind: 'assign',
    categorySet,
    id: categorySet.id,
    ...(module
      ? {
          module: {
            ...module,
            kind: 'assign',
          } as AnnotationCategorySetModuleInput,
        }
      : null),
  };
};

export const AnnotationCategoryForm = ({
  onSubmit,
  localAnnotationCategorySets,
  localAnnotationCategoryNames,
  ...props
}: AnnotationCategoryFormProps): ReactElement => {
  const {
    data: annotationCategorySetsData,
    loading: loadingAnnotationCategorySets,
  } = useQuery<{
    annotationCategorySets: {
      nodes: AnnotationCategorySet[];
    };
  }>(FETCH_ANNOTATION_CATEGORY_SETS, {
    skip: Boolean(localAnnotationCategorySets),
  });

  const annotationCategorySets = useMemo(() => {
    return localAnnotationCategorySets
      ? localAnnotationCategorySets
      : annotationCategorySetsData?.annotationCategorySets.nodes ?? [];
  }, [annotationCategorySetsData, localAnnotationCategorySets]);

  const { data: annotationCategoryData, loading: loadingAnnotationData } =
    useQuery<{
      annotationCategories: {
        collectionAttributes: {
          allAnnotationCategoryNames: string[];
        };
      };
    }>(FETCH_ANNOTATION_CATEGORY_NAMES, {
      skip: Boolean(localAnnotationCategoryNames),
    });

  const annotationCategoryNames = localAnnotationCategoryNames
    ? localAnnotationCategoryNames
    : annotationCategoryData?.annotationCategories.collectionAttributes
        .allAnnotationCategoryNames || [];

  const initialCategoryValues =
    props.mode === 'edit' ? props.initialValues : null;
  const initialNameValue = initialCategoryValues?.name ?? '';
  const initialColorValue = initialCategoryValues?.color
    ? `#${initialCategoryValues?.color}`
    : '';

  const initialAnnotationCategorySetInput = useMemo(
    () =>
      initialCategoryValues
        ? getInitialAnnotationCategorySetInputValue(
            initialCategoryValues,
            annotationCategorySets
          )
        : ({ kind: 'unset' } as AnnotationCategorySetInput),
    [initialCategoryValues, annotationCategorySets]
  );

  const [name, setName] = useState(initialNameValue);

  const trimmedName = name.replace(/\s+/g, ' ').trim();

  const [color, setColor] = useState(initialColorValue);
  const [annotationCategorySetInput, setAnnotationCategorySetInput] =
    useState<AnnotationCategorySetInput>(initialAnnotationCategorySetInput);
  const { addSnackbar } = useSnackbarMutations();

  const handleCreateCategory = (
    values: Record<string, FieldValue | null | AnnotationCategorySetInput>
  ) => {
    if (!HEXADECIMAL_PATTERN.test(color)) {
      addSnackbar({
        type: 'error',
        message: `${color} is not a valid color! please use a valid hexadecimal value.`,
      });
      return;
    }

    onSubmit({
      ...values,
      ...formatAnnotationCategorySetInputValue(annotationCategorySetInput),
      id: initialCategoryValues?.id ?? uuid(),
    } as AnnotationCategoryInput);
    /** Clear Form input after submit to have any user input */
  };

  const canSubmitAnnotationCategorySet =
    annotationCategorySetInput.kind === 'create'
      ? annotationCategorySetInput.name &&
        annotationCategorySetInput.code &&
        isNewAnnotationCategorySetCodeValid(
          annotationCategorySetInput.code,
          annotationCategorySets
        )
      : true;

  const isCategoryNameTaken =
    trimmedName !== initialNameValue &&
    annotationCategoryNames.includes(trimmedName);

  const initialValues = {
    category: initialCategoryValues as AnnotationCategory,
    set: initialAnnotationCategorySetInput,
  };

  const currentValues = {
    category: {
      name: trimmedName,
      color: color.substring(1),
    } as AnnotationCategory,
    set: annotationCategorySetInput,
  };

  const isValuesChanged = isAnnotationCategoryValuesChanged({
    initialValues,
    currentValues,
  });

  const canSubmit =
    trimmedName.length &&
    !isCategoryNameTaken &&
    color &&
    isValuesChanged &&
    (annotationCategorySetInput.kind === 'unset' ||
      canSubmitAnnotationCategorySet);

  return (
    <$AnnotationCategoryForm
      title={`${capitalize(props.mode)} Annotation Category`}
      loading={loadingAnnotationCategorySets || loadingAnnotationData}
    >
      <HStack as="form" spacing="8">
        <$AnnotationCategoryNameWrapper>
          <Input
            id="create-annotation-category-name-input-id"
            type="text"
            onChange={(value) => {
              setName(value);
            }}
            value={name}
            label="Name"
            required={true}
            isInvalid={isCategoryNameTaken}
            errorMessage={isCategoryNameTaken ? CATEGORY_NAME_EXISTS_TEXT : ''}
          />
        </$AnnotationCategoryNameWrapper>
        <$AnnotationCategoryColorWrapper>
          <ColorInput
            label="Color"
            id="create-annotation-category-color-input-id"
            onChange={(value) => {
              setColor(value);
            }}
            value={color}
            required={true}
            isInline={true}
          />
        </$AnnotationCategoryColorWrapper>
      </HStack>
      <AnnotationCategoryFormSetBlock
        annotationCategorySetInput={annotationCategorySetInput}
        annotationCategorySets={annotationCategorySets}
        updateAnnotationCategorySetInput={setAnnotationCategorySetInput}
        initialOpen={
          props.mode === 'edit' && annotationCategorySetInput.kind !== 'unset'
        }
      />
      <VStack>
        <Button
          id="create-annotation-category-submit-button-id"
          onClick={(e) => {
            e.preventDefault();
            handleCreateCategory({
              name: trimmedName,
              color,
              annotationCategorySet: annotationCategorySetInput,
            });
          }}
          small
          type="submit"
          disabled={!canSubmit}
        >
          Submit
        </Button>
      </VStack>
    </$AnnotationCategoryForm>
  );
};
