import { FetchResult, gql, useMutation } from '@apollo/client';
import { FETCH_ONBOARDING_BATCHES } from '../../../graphql/queries/FETCH_ONBOARDING_BATCHES';
import { FETCH_ONBOARDING_WSIS } from '../../../graphql/queries/FETCH_ONBOARDING_WSIS';
import useOnboardingBatchesSearchParams from '../../../hooks/useOnboardingBatchesSearchParams';

interface UpdateStatusData {
  wsisUuid: string[];
}
type UpdateOnboardingStatus = (ids: string[]) => Promise<FetchResult<void>>;

const updateOnboardingWsisStatusMutation = gql`
  mutation UPDATE_ONBOARDING_WSI_STATUS($wsisUuid: [String]!) {
    moveWsisToPendingStatus(wsisUuid: $wsisUuid)
  }
`;

/**
 * Returns a function to update the wsis status in the onboarding db
 */
export const useUpdateOnboardingWsisStatus = (
  batchId: string
): UpdateOnboardingStatus => {
  const searchParams = useOnboardingBatchesSearchParams();
  const [execute] = useMutation<void, UpdateStatusData>(
    updateOnboardingWsisStatusMutation,
    {
      refetchQueries: [
        {
          query: FETCH_ONBOARDING_BATCHES,
          variables: {
            page: searchParams.page,
            pageSize: searchParams.pageSize,
            association: searchParams.association,
            search: searchParams.search,
            createdBy: searchParams.createdBy,
            uploadingDate: searchParams.uploadingDate,
            status: searchParams.status,
          },
        },
        {
          query: FETCH_ONBOARDING_WSIS,
          variables: { batchId, injectBlackLayerReferences: false },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  return (wsisUuid: string[]) => execute({ variables: { wsisUuid } });
};
