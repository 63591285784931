import {
  FinalFormInput,
  FinalFormSelectSingle,
  ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS,
  OnboardingUploadListItemMultiChannelFile,
  OnboardingUploadListItemSingleChannelFile,
} from '@aignostics/components';
import React, { ReactElement, memo } from 'react';
import {
  type CancerSite,
  type Disease,
  type Morphology,
  type PreparationType,
  type SampleType,
  type Scanner,
  type Staining,
  type Tissue,
} from '../../../types';
import {
  MatchingWsisGroup,
  MultiChannelFormRow,
  SingleChannelFormRow,
  generateMatchingWsisSummary,
} from '../Form';
import {
  SelectWiderAndSnapToRightStyle,
  SelectWiderStyle,
} from './FileMetadataFieldset.styles';

export function FileMetadataFieldset({
  field,
  tissues,
  scanners,
  stainings,
  diseases,
  preparationTypes,
  sampleTypes,
  morphologies,
  cancerSites,
  fieldRemove,
  fieldIndex,
  showWarnings,
  shouldRenderDeleteButton,
  name,
  openStainingsErrorsModal,
  channelErrors = false,
  matchingWsisGroups = [],
  showExtraFields,
  onToggleChannelsClick,
  slideFileName,
  multiplexSlidesNamesWithVisibleChannels,
  hasChannelsOpen,
}: {
  field: SingleChannelFormRow | MultiChannelFormRow;
  tissues: Tissue[];
  scanners: Scanner[];
  stainings: Staining[];
  diseases: Disease[];
  preparationTypes: PreparationType[];
  sampleTypes: SampleType[];
  morphologies: Morphology[];
  cancerSites: CancerSite[];
  fieldRemove: (index: number) => void;
  fieldIndex: number;
  showWarnings: (name: string) => void;
  shouldRenderDeleteButton: boolean;
  name: string;
  openStainingsErrorsModal: () => void;
  channelErrors?: boolean;
  matchingWsisGroups: MatchingWsisGroup[];
  showExtraFields: boolean;
  onToggleChannelsClick: (
    isChannelsOpen: boolean,
    slideFileName: string,
    multiplexSlidesNamesWithVisibleChannels: string[]
  ) => void;
  slideFileName: string;
  multiplexSlidesNamesWithVisibleChannels: string[];
  hasChannelsOpen: boolean;
}): ReactElement {
  const extraFieldTabIndex = !showExtraFields ? -1 : 0;
  const relatedWsisCountSummary = generateMatchingWsisSummary(
    field,
    matchingWsisGroups
  );
  const onDelete = () => {
    showWarnings(slideFileName);
    fieldRemove(fieldIndex);
  };
  switch (field.type) {
    case 'single-channel': {
      return (
        <OnboardingUploadListItemSingleChannelFile
          onDeleteClick={onDelete}
          shouldRenderDeleteButton={shouldRenderDeleteButton}
          relatedWsisCountSummary={relatedWsisCountSummary}
          showExtraFields={showExtraFields}
          rowId={field.slideFile.filename}
        >
          <FinalFormSelectSingle
            name={`${name}.staining`}
            ariaLabel="select staining"
            required
            options={stainings.map(({ id, name }) => ({
              value: name || `Unknown (${id})`,
              label: name || `Unknown (${id})`,
            }))}
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.staining,
            }}
            showErrorMessage={false}
            selectStyle={SelectWiderStyle}
          />

          <FinalFormSelectSingle
            name={`${name}.scannerId`}
            ariaLabel="select scanner"
            required
            options={scanners.map(({ id, vendor, model }) => ({
              value: id,
              label: `${vendor}${model ? ` ${model}` : ''}`,
            }))}
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.scanner,
            }}
            showErrorMessage={false}
            selectStyle={SelectWiderStyle}
          />

          <FinalFormSelectSingle
            name={`${name}.tissue`}
            ariaLabel="select localization"
            required
            options={tissues.map(({ id, name }) => ({
              value: name || `Unknown (${id})`,
              label: name || `Unknown (${id})`,
            }))}
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.tissue,
            }}
            showErrorMessage={false}
            selectStyle={SelectWiderStyle}
          />

          <FinalFormInput
            ariaLabel="type patient external id"
            name={`${name}.patientExternalId`}
            autoComplete="off"
            style={{
              gridArea:
                ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.patientExternalId,
            }}
            showErrorMessage={false}
          />

          <FinalFormInput
            name={`${name}.caseId`}
            ariaLabel="type case id"
            autoComplete="off"
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.caseId,
            }}
            showErrorMessage={false}
          />

          <FinalFormInput
            name={`${name}.block`}
            ariaLabel="type block id"
            autoComplete="off"
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.block,
            }}
            showErrorMessage={false}
          />

          <FinalFormInput
            name={`${name}.section`}
            ariaLabel="type section id"
            autoComplete="off"
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.section,
            }}
            showErrorMessage={false}
          />

          <FinalFormSelectSingle
            name={`${name}.disease`}
            ariaLabel="select disease type"
            required
            options={diseases.map(({ name }) => ({
              value: name,
              label: name,
            }))}
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.disease,
            }}
            showErrorMessage={false}
            tabIndex={extraFieldTabIndex}
            disabled={Boolean(extraFieldTabIndex)}
            selectStyle={SelectWiderStyle}
          />

          <FinalFormSelectSingle
            name={`${name}.samplePreparation`}
            ariaLabel="select sample preparation "
            required
            options={preparationTypes.map(({ name }) => ({
              value: name,
              label: name,
            }))}
            style={{
              gridArea:
                ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.samplePreparation,
            }}
            showErrorMessage={false}
            tabIndex={extraFieldTabIndex}
            disabled={Boolean(extraFieldTabIndex)}
            selectStyle={SelectWiderStyle}
          />

          <FinalFormSelectSingle
            name={`${name}.sampleType`}
            ariaLabel="select sample type"
            required
            options={sampleTypes.map(({ name }) => ({
              value: name,
              label: name,
            }))}
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.sampleType,
            }}
            showErrorMessage={false}
            tabIndex={extraFieldTabIndex}
            disabled={Boolean(extraFieldTabIndex)}
            selectStyle={SelectWiderStyle}
          />

          <FinalFormSelectSingle
            name={`${name}.morphology`}
            ariaLabel="select morphology"
            options={morphologies.map(({ code, displayName }) => ({
              value: code,
              label: displayName,
            }))}
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.morphology,
            }}
            showErrorMessage={false}
            tabIndex={extraFieldTabIndex}
            disabled={Boolean(extraFieldTabIndex)}
            selectStyle={SelectWiderStyle}
          />

          <FinalFormSelectSingle
            name={`${name}.cancerSite`}
            ariaLabel="select cancer site"
            options={cancerSites.map(({ name }) => ({
              value: name,
              label: name,
            }))}
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.cancerSite,
            }}
            showErrorMessage={false}
            tabIndex={extraFieldTabIndex}
            disabled={Boolean(extraFieldTabIndex)}
            selectStyle={SelectWiderAndSnapToRightStyle}
          />
        </OnboardingUploadListItemSingleChannelFile>
      );
    }
    case 'multi-channel': {
      return (
        <OnboardingUploadListItemMultiChannelFile
          relatedWsisCountSummary={relatedWsisCountSummary}
          shouldRenderDeleteButton={shouldRenderDeleteButton}
          onDeleteClick={onDelete}
          channelErrors={channelErrors}
          showExtraFields={showExtraFields}
          onToggleChannelsClick={(isChannelsOpen) => {
            onToggleChannelsClick(
              isChannelsOpen,
              slideFileName,
              multiplexSlidesNamesWithVisibleChannels
            );
          }}
          channels={field.channels.map((slideChannel, slideChannelIndex) => {
            return (
              <FinalFormSelectSingle
                name={`${name}.channels[${slideChannelIndex}].staining`}
                key={`${field.slideFile.filename}_${slideChannelIndex}`}
                ariaLabel={`Select channel stainings of index ${slideChannelIndex}`}
                required
                label={slideChannel.name ?? `Channel ${slideChannelIndex + 1}`}
                options={stainings.map(({ id, name }) => ({
                  value: name || `Unknown (${id})`,
                  label: name || `Unknown (${id})`,
                }))}
                showErrorMessage={false}
                style={{ minWidth: 0 }}
                onClickWithError={openStainingsErrorsModal}
                selectStyle={SelectWiderStyle}
              />
            );
          })}
          hasChannelsOpen={hasChannelsOpen}
          rowId={field.slideFile.filename}
        >
          <FinalFormSelectSingle
            name={`${name}.parentAssigned.scannerId`}
            ariaLabel="select scanner"
            required
            options={scanners.map(({ id, vendor, model }) => ({
              value: id,
              label: `${vendor}${model ? ` ${model}` : ''}`,
            }))}
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.scanner,
            }}
            showErrorMessage={false}
            selectStyle={SelectWiderStyle}
          />

          <FinalFormSelectSingle
            name={`${name}.parentAssigned.tissue`}
            ariaLabel="select localization"
            required
            options={tissues.map(({ id, name }) => ({
              value: name || `Unknown (${id})`,
              label: name || `Unknown (${id})`,
            }))}
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.tissue,
            }}
            showErrorMessage={false}
            selectStyle={SelectWiderStyle}
          />

          <FinalFormInput
            name={`${name}.parentAssigned.patientExternalId`}
            ariaLabel="type patient external id"
            autoComplete="off"
            style={{
              gridArea:
                ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.patientExternalId,
            }}
            showErrorMessage={false}
          />

          <FinalFormInput
            name={`${name}.parentAssigned.caseId`}
            ariaLabel="type case id"
            autoComplete="off"
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.caseId,
            }}
            showErrorMessage={false}
          />

          <FinalFormInput
            name={`${name}.parentAssigned.block`}
            ariaLabel="type block id"
            autoComplete="off"
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.block,
            }}
            showErrorMessage={false}
          />

          <FinalFormInput
            name={`${name}.parentAssigned.section`}
            ariaLabel="type section id"
            autoComplete="off"
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.section,
            }}
            showErrorMessage={false}
          />

          <FinalFormSelectSingle
            name={`${name}.parentAssigned.disease`}
            ariaLabel="select disease type"
            required
            options={diseases.map(({ name }) => ({
              value: name,
              label: name,
            }))}
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.disease,
            }}
            showErrorMessage={false}
            tabIndex={extraFieldTabIndex}
            disabled={Boolean(extraFieldTabIndex)}
            selectStyle={SelectWiderStyle}
          />

          <FinalFormSelectSingle
            name={`${name}.parentAssigned.samplePreparation`}
            ariaLabel="select sample preparation"
            required
            options={preparationTypes.map(({ name }) => ({
              value: name,
              label: name,
            }))}
            style={{
              gridArea:
                ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.samplePreparation,
            }}
            showErrorMessage={false}
            tabIndex={extraFieldTabIndex}
            disabled={Boolean(extraFieldTabIndex)}
            selectStyle={SelectWiderStyle}
          />

          <FinalFormSelectSingle
            name={`${name}.parentAssigned.sampleType`}
            ariaLabel="select sample type"
            required
            options={sampleTypes.map(({ name }) => ({
              value: name,
              label: name,
            }))}
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.sampleType,
            }}
            showErrorMessage={false}
            tabIndex={extraFieldTabIndex}
            disabled={Boolean(extraFieldTabIndex)}
            selectStyle={SelectWiderStyle}
          />

          <FinalFormSelectSingle
            name={`${name}.parentAssigned.morphology`}
            ariaLabel="select morphology"
            options={morphologies.map(({ code, displayName }) => ({
              value: code,
              label: displayName,
            }))}
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.morphology,
            }}
            showErrorMessage={false}
            tabIndex={extraFieldTabIndex}
            disabled={Boolean(extraFieldTabIndex)}
            selectStyle={SelectWiderStyle}
          />

          <FinalFormSelectSingle
            name={`${name}.parentAssigned.cancerSite`}
            ariaLabel="select cancer site"
            options={cancerSites.map(({ name }) => ({
              value: name,
              label: name,
            }))}
            style={{
              gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.cancerSite,
            }}
            showErrorMessage={false}
            tabIndex={extraFieldTabIndex}
            disabled={Boolean(extraFieldTabIndex)}
            selectStyle={SelectWiderAndSnapToRightStyle}
          />
        </OnboardingUploadListItemMultiChannelFile>
      );
    }
  }
}

export default memo(FileMetadataFieldset);
