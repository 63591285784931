import { DiscreteProgressBarTileProps } from '@aignostics/components';
import {
  MultiChannelFormRowFilledReadOnly,
  MultiChannelSlideFileChannelFilledReadOnly,
  SingleChannelFormRowFilledReadOnly,
} from '../../pages/SetFileMetadataStep/Form';
import { hasOnlySuccessfulOnboardingStages } from '../../pages/SetFileMetadataStep/Form/hasOnlySuccessfulOnboardingStages';

/**
 * a function to calculate the combined onboarding progress of all the channels
 * of a multi channel slide.
 * @param channels an array of MultiChannelSlideFileChannelResultEnhancedReadonly
 * @returns        an array of DiscreteProgressBarTileProps
 */
export const getMultiChannelCombinedOnboardingProgress = (
  channels: MultiChannelSlideFileChannelFilledReadOnly[]
): DiscreteProgressBarTileProps[] => {
  return channels.reduce(
    (channelsOnboardingProgress: DiscreteProgressBarTileProps[], channel) => {
      return [...channelsOnboardingProgress, ...channel.onboardProgress];
    },
    []
  );
};

/**
 * a function to check if a slide has only successful stages ( single / multi channel )
 * @param slide SingleChannelSlideFormRowResultEnhancedReadonly | MultiChannelGroupedFormRowsResultEnhancedReadonly
 * @returns     a boolean
 */
export const slideHasOnlySuccessfulOnboardingStages = (
  slide: SingleChannelFormRowFilledReadOnly | MultiChannelFormRowFilledReadOnly
): boolean => {
  const onboardProgress =
    slide.type === 'multi-channel'
      ? getMultiChannelCombinedOnboardingProgress(slide.channels)
      : slide.onboardProgress;

  return hasOnlySuccessfulOnboardingStages(onboardProgress);
};
