import { Button } from '@aignostics/components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const $MenuSidebar = styled(motion.nav)`
  margin-top: ${({ theme }) => theme.spacings.button}px;
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: ${({ theme }) => theme.spacings.aside}px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text};
`;

export const $MenuList = styled.ul`
  width: 100%;
  overflow: auto;
`;

export const $MenuItemStyle = css`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: ${({ theme }) => theme.spacings[64]}px;
  white-space: nowrap;
  padding: ${({ theme }) => `${theme.spacings[8]}px`};
  padding-left: ${({ theme }) => `${theme.spacings[16]}px`};
  text-decoration: none;
  width: 100%;
  background-color: inherit;
  transition: 400ms;
  color: ${({ theme }) => theme.colors.text};
  border: 0;
  ${({ theme }) => theme.fontStyles.baseBold}
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.light};
  }
`;

export const $MenuItem = styled(motion(Link))`
  ${$MenuItemStyle}
`;

export const $SettingsContent = styled(motion.div)`
  border-top: 1px solid ${({ theme }) => theme.colors.text};
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
`;

export const $FooterContent = styled.div`
  text-align: left;
  padding: ${({ theme }) => theme.spacings[16]}px;
  font-size: 12px;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
  }
`;

export const $LogoutButton = styled(Button)`
  width: ${({ theme }) => theme.spacings.aside}px;
  align-items: center;
  justify-content: left;
  padding-left: ${({ theme }) => theme.spacings['16']}px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text};
  ${({ theme }) => theme.fontStyles.baseBold}
`;

export const $UserEmailDisplay = styled(Link)`
  width: ${({ theme }) => `calc(100% - ${theme.spacings[40]}px)`};
  word-wrap: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme }) => theme.fontStyles.baseBold}
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
`;

export const $FooterContainer = styled.div`
  gap: ${({ theme }) => theme.spacings.base}px;
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.spacings.aside}px;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.lighter};
  bottom: 0;
  left: 0;
`;
