import {
  Button,
  Icon,
  OnboardingReadOnlyList,
  OnboardingReadOnlyListItemLogoLabel,
  OnboardingReadOnlyListItemMultiChannelFile,
  OnboardingReadOnlyListItemSingleChannelFile,
  generateOnboardingReadOnlyMultiChannelInput,
  generateOnboardingReadOnlyMultiplexChannels,
  generateOnboardingReadOnlySingleChannelInput,
  prettyFormatBytes,
  useDisclosure,
} from '@aignostics/components';
import { OrganizationRole, User } from '@aignostics/core';
import { formatDate, pluralize } from '@aignostics/utils';
import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { countSucceededSlideInOnboardingBatch } from '../../pages/SetFileMetadataStep/Form';
import { BatchReadOnlyForm } from '../../pages/SetFileMetadataStep/Form/form.state.types';
import {
  UPLOADING_FILE_STATUS,
  useUploadFilesContext,
} from '../../pages/UploadSlides';
import { Morphology } from '../../types';
import { useSelectedOrganizationUuid } from '../../utils/useSelectedOrganizationUuid';
import { ReadOnlyTableEntryActions } from './OnboardingBatch.ReadOnlyTableEntryActions.component';
import { ONBOARDING_BATCH_COMPLETED_STATUSES } from './const';
import { slideHasOnlySuccessfulOnboardingStages } from './utils';

const $BatchTableEntrySlides = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings[8]}px;
  padding-top: ${({ theme }) => theme.spacings[8]}px;
  padding-bottom: ${({ theme }) => theme.spacings[16]}px;
`;

const $BatchTableEntry = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.light};
  &:first-of-type {
    border-bottom: 0;
    ${$BatchTableEntrySlides} {
      padding-bottom: 0;
    }
  }
`;

const $BatchTableEntryHeader = styled.div`
  padding: ${({ theme }) => theme.spacings[12]}px 0;
  font-size: ${({ theme }) => theme.fontStyles.small.fontSize};
  display: flex;
  justify-content: space-between;
`;

const $BatchTableEntryTitle = styled.div`
  ${({ theme }) => theme.fontStyles.largeBold};
  color: ${({ theme }) => theme.colors.black};
  align-items: center;
  display: flex;
`;

const $BatchTableEntryTitleButton = styled(Button)`
  background-color: transparent;
  padding: 0;
  > span {
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacings[12]}px;
  }
`;

const getInitialMultiplexSlidesWithVisibleChannels = (
  slides: BatchReadOnlyForm['slides']
): number[] => {
  const initMultiplexSlidesWithVisibleChannels: number[] = [];
  slides.forEach((slide, index) => {
    if (slide.type === 'multi-channel') {
      initMultiplexSlidesWithVisibleChannels.push(index);
    }
  });
  return initMultiplexSlidesWithVisibleChannels;
};

type OnboardingBatchReadOnlyTableEntryProps = {
  onboardingBatch: BatchReadOnlyForm;
  isLatestBatchEntry: boolean;
  isValidating: boolean;
  isValidationSuccessfullyTriggered: boolean;
  onBatchValidationTrigger?: () => void;
  role: OrganizationRole;
  currentUser: User;
  morphologies: Morphology[];
};
export const OnboardingBatchReadOnlyTableEntry = ({
  onboardingBatch,
  isLatestBatchEntry,
  isValidating,
  isValidationSuccessfullyTriggered,
  onBatchValidationTrigger,
  role,
  currentUser,
  morphologies,
}: OnboardingBatchReadOnlyTableEntryProps): ReactElement => {
  const { batch_id, slides, status, createdAt } = onboardingBatch;

  const [showExtraFields, setShowExtraFields] = useState(false);
  const [
    multiplexSlidesWithVisibleChannels,
    setMultiplexSlidesWithVisibleChannels,
  ] = useState(getInitialMultiplexSlidesWithVisibleChannels(slides));
  const organizationUuid = useSelectedOrganizationUuid();

  const onToggleChannelsClick = (
    isChannelsOpen: boolean,
    slideIndex: number
  ) => {
    const updatedMultiplexSlidesWithVisibleChannels = !isChannelsOpen
      ? [...multiplexSlidesWithVisibleChannels, slideIndex]
      : multiplexSlidesWithVisibleChannels.filter((vis) => vis !== slideIndex);

    setMultiplexSlidesWithVisibleChannels(
      updatedMultiplexSlidesWithVisibleChannels
    );
  };

  const succeededSlidesCount = countSucceededSlideInOnboardingBatch(slides);

  const showBatchEntrySlides = useDisclosure(isLatestBatchEntry);

  const { uploadState } = useUploadFilesContext();

  const isUploading =
    uploadState.status === UPLOADING_FILE_STATUS.UPLOADING &&
    uploadState.batchId === batch_id;

  const lastEntryText = isLatestBatchEntry ? 'Last entry:' : '';
  const slidesText = pluralize('slide', slides.length);
  const slidesCount = slides.length;
  /** Used when batch is too big, and we have fetched only first thousand of slides */
  const slidesCountExtra =
    onboardingBatch.totalSlideFiles !== slidesCount
      ? ` (out of ${onboardingBatch.totalSlideFiles} total)`
      : '';

  const succeededOnText = !isLatestBatchEntry
    ? `on ${formatDate(createdAt)}`
    : '';

  const completedBatchTitle = `${lastEntryText} ${succeededSlidesCount}/${slidesCount}${slidesCountExtra} ${slidesText} succeeded ${succeededOnText}`;
  const inProgressBatchTitle = `${slidesCount}${slidesCountExtra} ${slidesText}`;
  const batchTitle = (
    <$BatchTableEntryTitle>
      {ONBOARDING_BATCH_COMPLETED_STATUSES.includes(status)
        ? completedBatchTitle
        : inProgressBatchTitle}
    </$BatchTableEntryTitle>
  );

  return (
    <$BatchTableEntry>
      <$BatchTableEntryHeader>
        {!isLatestBatchEntry ? (
          <$BatchTableEntryTitleButton
            onClick={() => {
              if (showBatchEntrySlides.isOpen) {
                showBatchEntrySlides.close();
              } else {
                showBatchEntrySlides.open();
              }
            }}
            variant="ghost"
          >
            <Icon
              icon={showBatchEntrySlides.isOpen ? 'ChevronUp' : 'ChevronDown'}
            />
            {batchTitle}
          </$BatchTableEntryTitleButton>
        ) : (
          batchTitle
        )}
        <ReadOnlyTableEntryActions
          onboardingBatch={onboardingBatch}
          isLatestBatchEntry={isLatestBatchEntry}
          onBatchValidationTrigger={onBatchValidationTrigger}
          isValidating={isValidating}
          isValidationSuccessfullyTriggered={isValidationSuccessfullyTriggered}
          role={role}
          currentUser={currentUser}
        />
      </$BatchTableEntryHeader>

      {(isLatestBatchEntry ||
        (!isLatestBatchEntry && showBatchEntrySlides.isOpen)) && (
        <$BatchTableEntrySlides>
          <OnboardingReadOnlyList
            showExtraFields={showExtraFields}
            onToggleExtraFieldsClick={() => {
              setShowExtraFields(!showExtraFields);
            }}
            fixedColumn={slides.map((slide, index) => {
              const slideId =
                slide.type === 'single-channel'
                  ? slide.id
                  : slide.parentAssigned.id;

              const { filename, size } = slide.slideFile;

              const uploadloadStateSlideIndex = isUploading
                ? uploadState.fileSlides.findIndex(
                    (fileSlide) => fileSlide.file.name === filename
                  )
                : undefined;

              const uploadProgress =
                isUploading && uploadloadStateSlideIndex !== undefined
                  ? uploadState.progress[uploadloadStateSlideIndex]
                  : undefined;

              const uploadStatus =
                slide.type === 'single-channel'
                  ? slide.uploadProgress
                  : undefined;

              const onboardingProgress =
                slide.type === 'single-channel'
                  ? slide.onboardProgress
                  : undefined;

              return (
                <OnboardingReadOnlyListItemLogoLabel
                  key={`${filename}-read-only-single-channel-file-logo-label`}
                  title={filename}
                  subtitle={prettyFormatBytes(size)}
                  batchStatus={status}
                  uploadProgress={uploadProgress}
                  uploadStatus={uploadStatus}
                  onboardingProgress={onboardingProgress}
                  slideViewUrl={
                    slideHasOnlySuccessfulOnboardingStages(slide)
                      ? `/${organizationUuid}/admin/wsi/${slideId}`
                      : undefined
                  }
                  isMultiplex={slide.type === 'multi-channel'}
                  isExpanded={multiplexSlidesWithVisibleChannels.includes(
                    index
                  )}
                  totalChannels={
                    slide.type === 'multi-channel' ? slide.channels.length : 0
                  }
                />
              );
            })}
            listId={`onboarding-read-only-list-${batch_id}`}
          >
            {slides.map((slide, index) => {
              const row =
                slide.type === 'single-channel' ? slide : slide.parentAssigned;

              const slideFileName = slide.slideFile.filename;

              const {
                patientExternalId,
                caseId,
                block,
                section,
                scannerId,
                tissue,
                disease,
                samplePreparation,
                sampleType,
                morphology,
                cancerSite,
              } = row;

              switch (slide.type) {
                case 'single-channel':
                  return (
                    <OnboardingReadOnlyListItemSingleChannelFile
                      key={`${slideFileName}-read-only-single-channel-file`}
                      showExtraFields={showExtraFields}
                    >
                      {generateOnboardingReadOnlySingleChannelInput({
                        patientExternalId,
                        caseId,
                        block,
                        section,
                        scanner: scannerId,
                        tissue,
                        staining: slide.staining,
                        disease,
                        samplePreparation,
                        sampleType,
                        morphology:
                          morphologies.find((m) => m.code === morphology)
                            ?.displayName ?? `invalid value (${morphology})`,
                        cancerSite,
                      })}
                    </OnboardingReadOnlyListItemSingleChannelFile>
                  );
                case 'multi-channel':
                  return (
                    <OnboardingReadOnlyListItemMultiChannelFile
                      key={`${slideFileName}-read-only-multi-channel-file`}
                      channels={generateOnboardingReadOnlyMultiplexChannels(
                        slide.channels,
                        slide.parentAssigned.name,
                        status
                      )}
                      showExtraFields={showExtraFields}
                      onUpdateChannelsExpanded={(isChannelsOpen) => {
                        onToggleChannelsClick(isChannelsOpen, index);
                      }}
                    >
                      {generateOnboardingReadOnlyMultiChannelInput({
                        patientExternalId,
                        caseId,
                        block,
                        section,
                        scanner: scannerId,
                        tissue,
                        disease,
                        samplePreparation,
                        sampleType,
                        morphology,
                        cancerSite,
                      })}
                    </OnboardingReadOnlyListItemMultiChannelFile>
                  );
              }
            })}
          </OnboardingReadOnlyList>
        </$BatchTableEntrySlides>
      )}
    </$BatchTableEntry>
  );
};
