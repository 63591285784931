import { gql } from '@apollo/client';

export const GET_RELATED_LAYERS = gql`
  query GET_RELATED_LAYERS(
    $subProjectId: UUID!
    $wsiId: UUID!
    $mode: RelatedLayersOverlayMode
  ) {
    relatedLayers(subProjectId: $subProjectId, wsiId: $wsiId, mode: $mode) {
      stainings {
        id
        name
        stainingName
        isActive
        scanner {
          vendor
          model
        }
      }
      fluorescence {
        id
        name
        channelsCount
        firstChannel
        isActive
      }
    }
  }
`;
