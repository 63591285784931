import { BatchEditForm, OnboardingFileWithMetadata } from './Form';

export function getTotalFileSize(
  files: OnboardingFileWithMetadata[],
  onboardingBatch?: BatchEditForm
): number {
  if (onboardingBatch) {
    const totalEditedFilesSize = onboardingBatch.slides.reduce(
      (acc, slide) => acc + slide.slideFile.size,
      0
    );
    return totalEditedFilesSize;
  }
  const totalFilesSize = files.reduce((acc, slide) => acc + slide.size, 0);
  return totalFilesSize;
}
