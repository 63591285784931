import { Login } from '@aignostics/management-ui';
import React from 'react';
import { buildTimestamp } from '../../../metadata';
import { SentryRoutes } from '../../../sentry';
import { useAuthController, useAuthState } from '../../App/AuthProvider';
import { useAppConfig } from '../../App/ConfigProvider';
import { appConfig } from '../../App/appConfig';

export const LoginWrapper = (): JSX.Element => {
  const { state } = useAuthState();
  const { signIn } = useAuthController();
  const { logo, loginBackgroundSrc } = useAppConfig();
  const { version, buildEnv } = appConfig;

  return (
    <Login
      state={state}
      signIn={signIn}
      buildTimestamp={buildTimestamp}
      logo={logo}
      loginBackgroundSrc={loginBackgroundSrc}
      version={version}
      sentryRoutes={<SentryRoutes />}
      buildEnv={buildEnv}
    />
  );
};
