import { RefObject, useEffect } from 'react';

const useClickOutside = (
  refs: RefObject<HTMLElement> | RefObject<HTMLElement>[],
  handler: (event: Event) => void
): void => {
  useEffect(() => {
    const listener = (event: Event) => {
      const eventTarget = event.target as Node;
      /** Return if clicked element has unmounted itself */
      if (!document.contains(eventTarget)) {
        return;
      }

      if (Array.isArray(refs)) {
        if (refs.some((ref) => ref.current?.contains(eventTarget))) {
          return;
        }
        /** Return if clicked element is within ref element */
      } else if (refs.current?.contains(eventTarget)) {
        return;
      }
      /** Fire click handler */
      handler(event);
    };

    const element =
      document.getElementById('modal-element-visible') ?? document;
    element.addEventListener('click', listener);

    return () => {
      element.removeEventListener('click', listener);
    };
  }, [refs, handler]);
};

export default useClickOutside;
