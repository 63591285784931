import { useSnackbarMutations } from '@aignostics/components';
import { User } from '@aignostics/core';
import { useMutation, useQuery } from '@apollo/client';
import { useReducer } from 'react';
import { ADD_ONBOARDED_BATCH_TO_SUB_PROJECT } from './ADD_ONBOARDED_BATCH_TO_SUB_PROJECT';
import {
  ActionTypes,
  AssignBatchModalState,
  initialState,
  reducer,
} from './AssignBatchModal.reducer';
import { CREATE_PROJECT } from './CREATE_PROJECT';
import { CREATE_SUB_PROJECT } from './CREATE_SUB_PROJECT.queries';
import { FETCH_ADMIN_SUB_PROJECT_DETAILS } from './FETCH_ADMIN_SUBPROJECT_DETAILS';
import {
  FETCH_PROJECTS_LIST,
  FETCH_SUBPROJECTS_LIST,
} from './FETCH_PROJECTS_LIST';
import { FETCH_SUBPROJECT_WSIS } from './FETCH_SUBPROJECT_WSIS';
import {
  READ_SUBPROJECT_SLIDES,
  READ_SUBPROJECT_SLIDES_VARIABLES,
} from './READ_SUBPROJECT_SLIDES.queries';
import { cacheCreateProject } from './cacheUpdaters';

type ProjectType = {
  value: string;
  label: string;
};

type UseAssignBatchToProject = {
  changeProject: (projectId: string | null) => void;
  setProjectName: (projectName: string) => void;
  setSubProjectName: (subProjectName: string) => void;
  resetState: () => void;
  toggleCreateSubProject: () => void;
  toggleCreateProject: () => void;
  changeSubProject: (subProjectId: string | null) => void;
  assignToProject: () => Promise<void>;
  subProjectsData: {
    subProjectsList: ProjectType[];
    loadingSubProjects: boolean;
  };
  projectsData: {
    projectsList: ProjectType[];
    loadingProjects: boolean;
  };
  state: AssignBatchModalState;
};

export const ADMIN_ASSIGNED_SLIDES_PAGE_SIZE = 20;

function useAssignBatchToProject(
  batchId: string | null,
  currentUser: User
): UseAssignBatchToProject {
  const { addSnackbar } = useSnackbarMutations();
  const [{ assignmentComplete, project, subProject, isSubmitting }, dispatch] =
    useReducer(reducer, initialState);

  const { data: projectsList, loading: loadingProjects } = useQuery(
    FETCH_PROJECTS_LIST,
    {
      variables: {
        isAdminView: true,
      },
    }
  );

  const [createProjectMutation] = useMutation<{
    createProject: {
      id: string;
      subProjectId: string;
    };
  }>(CREATE_PROJECT, {
    refetchQueries: [FETCH_SUBPROJECT_WSIS],
    onError: (e) => {
      addSnackbar({
        message: e.message,
        type: 'error',
      });
    },
  });

  const [createSubProjectMutation] = useMutation<{ createSubProject: string }>(
    CREATE_SUB_PROJECT,
    {
      onError: (e) => {
        addSnackbar({
          message: e.message,
          type: 'error',
        });
      },
    }
  );

  const { data: subProjectsList, loading: loadingSubProjects } = useQuery(
    FETCH_SUBPROJECTS_LIST,
    {
      skip: !project.id,
      variables: { projectId: project.id, isAdminView: true },
      fetchPolicy: 'no-cache',
    }
  );

  const [addBatchToSubProjectMutation] = useMutation<void>(
    ADD_ONBOARDED_BATCH_TO_SUB_PROJECT,
    {
      onCompleted: () => {
        dispatch({ type: ActionTypes.setAssignmentComplete });
      },
      onError: (e) => {
        dispatch({ type: ActionTypes.setSubmitting, isSubmitting: false });

        addSnackbar({
          message: e.message,
          type: 'error',
        });
      },
    }
  );

  const createProject = async (variables: {
    name: string;
    subProjectName: string;
    isVisible: boolean;
  }): Promise<{ id: string; subProjectId: string } | undefined> => {
    const { name, subProjectName } = variables;
    const newProject = await createProjectMutation({
      variables,
      update: cacheCreateProject(
        { name },
        { name: subProjectName },
        currentUser
      ),
    });

    return newProject.data?.createProject;
  };

  const createSubProject = async (variables: {
    name: string;
    projectId: string;
    isVisible: boolean;
  }): Promise<string | undefined> => {
    const newSubProject = await createSubProjectMutation({
      variables,
    });

    return newSubProject.data?.createSubProject;
  };

  const addBatchToSubProject = async (
    subProjectId: string | null | undefined,
    projectId: string | null | undefined
  ) => {
    if (!subProjectId || !batchId) return;

    const readSubProjectSlidesVariables: READ_SUBPROJECT_SLIDES_VARIABLES = {
      subProjectId,
      page: 1,
      pageSize: ADMIN_ASSIGNED_SLIDES_PAGE_SIZE,
      search: '',
      associations: [],
      batches: [],
      case: '',
      sortBy: 'name',
      isAsc: true,
      objectivePowers: [],
      scanners: [],
      stainings: [],
      tissues: [],
      diseases: [],
    };

    await addBatchToSubProjectMutation({
      awaitRefetchQueries: true,
      variables: {
        batchId,
        subProjectId,
      },
      refetchQueries: [
        {
          query: READ_SUBPROJECT_SLIDES,
          variables: readSubProjectSlidesVariables,
        },
        {
          query: FETCH_ADMIN_SUB_PROJECT_DETAILS,
          variables: { subProjectId },
        },
      ],
      update: (cache) => {
        if (projectId) {
          cache.evict({ id: `Project:${projectId}` });
          cache.gc();
        }
      },
    });
  };

  const assignToProject = async () => {
    dispatch({ type: ActionTypes.setSubmitting, isSubmitting: true });

    if (project.isNew) {
      if (!project.name || !subProject.name) return;
      const newProject = await createProject({
        name: project.name,
        subProjectName: subProject.name,
        isVisible: true,
      });

      if (!newProject) return;

      dispatch({ type: ActionTypes.setProjectId, projectId: newProject.id });

      if (!newProject.subProjectId) return;

      dispatch({
        type: ActionTypes.setSubProjectId,
        subProjectId: newProject.subProjectId,
      });

      return addBatchToSubProject(newProject.subProjectId, newProject.id);
    }
    if (subProject.isNew && !project.isNew) {
      if (!subProject.name || !project.id) return;

      const newSubProject = await createSubProject({
        name: subProject.name,
        projectId: project.id,
        isVisible: true,
      });

      if (!newSubProject) return;

      dispatch({
        type: ActionTypes.setSubProjectId,
        subProjectId: newSubProject,
      });

      return addBatchToSubProject(newSubProject, project.id);
    }
    return addBatchToSubProject(subProject.id, project.id);
  };

  const changeProject = (projectId: string | null) => {
    dispatch({ type: ActionTypes.setProjectId, projectId });
  };

  const setProjectName = (projectName: string) => {
    dispatch({ type: ActionTypes.setProjectName, projectName });
  };
  const setSubProjectName = (subProjectName: string) => {
    dispatch({ type: ActionTypes.setSubProjectName, subProjectName });
  };

  const resetState = () => {
    dispatch({ type: ActionTypes.reset });
  };

  const toggleCreateSubProject = () => {
    dispatch({ type: ActionTypes.toggleCreateSubProject });
  };

  const toggleCreateProject = () => {
    dispatch({ type: ActionTypes.toggleCreateProject });
  };
  const changeSubProject = (subProjectId: string | null) => {
    dispatch({
      type: ActionTypes.setSubProjectId,
      subProjectId,
    });
  };

  return {
    changeProject,
    setProjectName,
    setSubProjectName,
    resetState,
    toggleCreateSubProject,
    toggleCreateProject,
    changeSubProject,
    assignToProject,
    subProjectsData: {
      subProjectsList: subProjectsList?.project?.subProjects.nodes,
      loadingSubProjects,
    },
    projectsData: {
      projectsList: projectsList?.projects.nodes,
      loadingProjects,
    },
    state: { isSubmitting, assignmentComplete, project, subProject },
  };
}

export default useAssignBatchToProject;
