import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useReducer,
  type Dispatch,
} from 'react';

import uploadStateReducer, {
  UploadFilesAction,
  UploadFilesState,
  UPLOADING_FILE_STATUS,
} from './uploadStateReducer';

type UploadFilesContextValue = {
  uploadState: UploadFilesState;
  dispatch: Dispatch<UploadFilesAction>;
};
const UploadFilesContext = createContext<UploadFilesContextValue>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  undefined as any
);

export const useUploadFilesContext = (): UploadFilesContextValue =>
  useContext(UploadFilesContext);

interface OnboardingProviderProps {
  children: ReactNode;
}

const OnboardingProvider = ({
  children,
}: OnboardingProviderProps): ReactElement => {
  const [uploadState, dispatch] = useReducer(uploadStateReducer, {
    status: UPLOADING_FILE_STATUS.IDLE,
  });

  return (
    <UploadFilesContext.Provider value={{ uploadState, dispatch }}>
      {children}
    </UploadFilesContext.Provider>
  );
};

export default OnboardingProvider;
