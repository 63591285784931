import {
  $OnboardingUploadListHeaderWrapper,
  $ToggleExtraFieldsButton,
  FinalFormInput,
  FinalFormSelectSingle,
  Icon,
  ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS,
  ONBOARDING_UPLOAD_LIST_HEADER_PLACEHOLDERS,
} from '@aignostics/components';
import { FormApi } from 'final-form';
import React, { ReactElement } from 'react';
import { useOnboardingMetricsHook } from '../../../providers/sentry/OnboardingMetricsProvider';
import {
  type CancerSite,
  type Disease,
  type Morphology,
  type PreparationType,
  type SampleType,
  type Scanner,
  type Staining,
  type Tissue,
} from '../../../types';
import { BatchCreateForm, updateFields } from '../Form';
import {
  SelectWiderAndSnapToRightStyle,
  SelectWiderStyle,
} from './FileMetadataFieldset.styles';

interface FileMetadataFieldsetHeaderProps {
  scanners: Scanner[];
  stainings: Staining[];
  tissues: Tissue[];
  diseases: Disease[];
  preparationTypes: PreparationType[];
  sampleTypes: SampleType[];
  morphologies: Morphology[];
  cancerSites: CancerSite[];
  onToggleExtraFieldsClick: () => void;
  showExtraFields: boolean;
  formApi: FormApi<BatchCreateForm>;
}

export const FileMetadataFieldsetHeader = ({
  scanners,
  stainings,
  tissues,
  diseases,
  preparationTypes,
  sampleTypes,
  morphologies,
  cancerSites,
  showExtraFields,
  onToggleExtraFieldsClick,
  formApi,
}: FileMetadataFieldsetHeaderProps): ReactElement => {
  const {
    recordFromInputHeaderInteraction,
    endRecordFromInputHeaderInteraction,
  } = useOnboardingMetricsHook();

  const onChangeUpdateFields = (
    fieldName: string,
    fieldValue: string | undefined,
    formApi: FormApi<BatchCreateForm>
  ) => {
    const slidesCount = formApi.getState().values.slides.length;
    recordFromInputHeaderInteraction({
      slidesCount,
    });
    updateFields(fieldName, fieldValue, formApi);
    endRecordFromInputHeaderInteraction();
  };
  return (
    <$OnboardingUploadListHeaderWrapper showExtraFields={showExtraFields}>
      <FinalFormSelectSingle
        name="apply-all.staining"
        label="Staining*"
        options={stainings.map(({ id, name }) => ({
          value: name || `Unknown (${id})`,
          label: name || `Unknown (${id})`,
        }))}
        style={{ gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.staining }}
        onChangeListener={(v) => {
          onChangeUpdateFields('staining', v, formApi);
        }}
        isHeadingField
        selectStyle={SelectWiderStyle}
      />
      <FinalFormSelectSingle
        name="apply-all.scannerId"
        label="Scanner*"
        options={scanners.map(({ id, vendor, model }) => ({
          value: id,
          label: `${vendor}${model ? ` ${model}` : ''}`,
        }))}
        style={{ gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.scanner }}
        onChangeListener={(v) => {
          onChangeUpdateFields('scannerId', v, formApi);
        }}
        isHeadingField
        selectStyle={SelectWiderStyle}
      />
      <FinalFormSelectSingle
        name="apply-all.tissue"
        label="Localization*"
        options={tissues.map(({ id, name }) => ({
          value: name || `Unknown (${id})`,
          label: name || `Unknown (${id})`,
        }))}
        style={{ gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.tissue }}
        onChangeListener={(v) => {
          onChangeUpdateFields('tissue', v, formApi);
        }}
        isHeadingField
        selectStyle={SelectWiderStyle}
      />
      <FinalFormInput
        name="apply-all.patientExternalId"
        label="Patient ID"
        placeholder={
          ONBOARDING_UPLOAD_LIST_HEADER_PLACEHOLDERS.patientExternalId
        }
        autoComplete="off"
        style={{
          gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.patientExternalId,
        }}
        onBlur={(v) => {
          onChangeUpdateFields('patientExternalId', v, formApi);
        }}
        isHeadingField
      />
      <FinalFormInput
        name="apply-all.caseId"
        label="Case ID*"
        placeholder={ONBOARDING_UPLOAD_LIST_HEADER_PLACEHOLDERS.caseId}
        autoComplete="off"
        style={{ gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.caseId }}
        onBlur={(v) => {
          onChangeUpdateFields('caseId', v, formApi);
        }}
        isHeadingField
      />
      <FinalFormInput
        name="apply-all.block"
        label="Block"
        placeholder={ONBOARDING_UPLOAD_LIST_HEADER_PLACEHOLDERS.block}
        autoComplete="off"
        style={{ gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.block }}
        onBlur={(v) => {
          onChangeUpdateFields('block', v, formApi);
        }}
        isHeadingField
      />
      <FinalFormInput
        name="apply-all.section"
        label="Section"
        placeholder={ONBOARDING_UPLOAD_LIST_HEADER_PLACEHOLDERS.section}
        autoComplete="off"
        style={{ gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.section }}
        onBlur={(v) => {
          onChangeUpdateFields('section', v, formApi);
        }}
        isHeadingField
      />
      <FinalFormSelectSingle
        name="apply-all.disease"
        label="Disease*"
        options={diseases.map(({ name }) => ({
          value: name,
          label: name,
        }))}
        tabIndex={!showExtraFields ? -1 : 0}
        style={{ gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.disease }}
        onChangeListener={(v) => {
          onChangeUpdateFields('disease', v, formApi);
        }}
        isHeadingField
        selectStyle={SelectWiderStyle}
      />
      <FinalFormSelectSingle
        name="apply-all.samplePreparation"
        label="Preparation Type*"
        options={preparationTypes.map(({ name }) => ({
          value: name,
          label: name,
        }))}
        tabIndex={!showExtraFields ? -1 : 0}
        style={{
          gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.samplePreparation,
        }}
        onChangeListener={(v) => {
          onChangeUpdateFields('samplePreparation', v, formApi);
        }}
        isHeadingField
        selectStyle={SelectWiderStyle}
      />
      <FinalFormSelectSingle
        name="apply-all.sampleType"
        label="Sample Type*"
        options={sampleTypes.map(({ name }) => ({
          value: name,
          label: name,
        }))}
        tabIndex={!showExtraFields ? -1 : 0}
        style={{
          gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.sampleType,
        }}
        onChangeListener={(v) => {
          onChangeUpdateFields('sampleType', v, formApi);
        }}
        isHeadingField
        selectStyle={SelectWiderStyle}
      />
      <FinalFormSelectSingle
        name="apply-all.morphology"
        label="Morphology*"
        options={morphologies.map(({ code, displayName }) => ({
          value: code,
          label: displayName,
        }))}
        tabIndex={!showExtraFields ? -1 : 0}
        style={{
          gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.morphology,
        }}
        onChangeListener={(v) => {
          onChangeUpdateFields('morphology', v, formApi);
        }}
        isHeadingField
        selectStyle={SelectWiderStyle}
      />
      <FinalFormSelectSingle
        name="apply-all.cancerSite"
        label="Cancer Site*"
        options={cancerSites.map(({ name }) => ({
          value: name,
          label: name,
        }))}
        tabIndex={!showExtraFields ? -1 : 0}
        style={{
          gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.cancerSite,
        }}
        onChangeListener={(v) => {
          onChangeUpdateFields('cancerSite', v, formApi);
        }}
        isHeadingField
        selectStyle={SelectWiderAndSnapToRightStyle}
      />
      <$ToggleExtraFieldsButton
        onClick={(event) => {
          onToggleExtraFieldsClick();
          event.preventDefault();
        }}
        small
      >
        <Icon icon={!showExtraFields ? 'TableExpand' : 'TableCollapse'} />
      </$ToggleExtraFieldsButton>
    </$OnboardingUploadListHeaderWrapper>
  );
};
