import { pluralize } from '@aignostics/utils';
import { ValidationErrors } from 'final-form';
import React, { type ReactElement } from 'react';
import { FormSpy } from 'react-final-form';
import { BatchCreateForm } from '../../SetFileMetadataStep/Form';
import {
  $Button,
  $Footer,
  $FooterElements,
  $FooterWrapper,
  $SlideCount,
} from '../../SetFileMetadataStep/SetFileMetadataStepFooter/SetFileMetadataStepFooter.styles';

export type TotalAndValidSlideCount = {
  total: number;
  totalByteSize: number;
  validFilenames: Set<string>;
  invalidFilenames: Set<string>;
};

export const SetFileMetadataStepFooter = ({
  onReset,
  onSubmit,
  totalAndValidSlideCount,
}: {
  onReset: () => void;
  onSubmit: (errors: ValidationErrors) => void;
  totalAndValidSlideCount: TotalAndValidSlideCount | null;
}): ReactElement => {
  return (
    <$FooterWrapper aria-label="Validation status">
      <$Footer>
        <FormSpy<BatchCreateForm, BatchCreateForm>
          subscription={{ errors: true, submitting: true }}
          render={({ errors, submitting }) => {
            return (
              <$FooterElements>
                {totalAndValidSlideCount && (
                  <$SlideCount>
                    {totalAndValidSlideCount.validFilenames.size}/
                    {totalAndValidSlideCount.total}{' '}
                    {pluralize('slide', totalAndValidSlideCount.total)}
                    {' have correct metadata'}
                  </$SlideCount>
                )}
                <div>
                  <$Button
                    variant="primaryOutline"
                    type="reset"
                    onClick={onReset}
                    disabled={submitting}
                  >
                    Reset
                  </$Button>
                  <$Button
                    type="submit"
                    disabled={submitting}
                    onClick={() => {
                      onSubmit(errors);
                    }}
                  >
                    Continue
                  </$Button>
                </div>
              </$FooterElements>
            );
          }}
        />
      </$Footer>
    </$FooterWrapper>
  );
};
