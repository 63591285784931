import { MultiChannelFormRow, SingleChannelFormRow } from './form.state.types';

/**
 * a function to get a slide's filename
 * @param slide SingleChannelSlideFormRow | MultiChannelGroupedFormRows
 * @returns     filename
 */

export const getRowFileName = (
  slide: SingleChannelFormRow | MultiChannelFormRow
): string => {
  const row = slide.type === 'single-channel' ? slide : slide.parentAssigned;
  return row.name ?? '';
};
