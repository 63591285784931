import {
  MultiChannelFormRowFilledReadOnly,
  SingleChannelFormRowFilledReadOnly,
} from './form.state.types';
import { hasOnlySuccessfulOnboardingStages } from './hasOnlySuccessfulOnboardingStages';

/**
 * a function to count total succeeded slides in a given slides array
 * @param slides an array of SingleChannelSlideFormRowResultEnhancedReadonly
                 | MultiChannelGroupedFormRowsResultEnhancedReadonly
 * @returns      the number of total succeeded slides
 */

export const countSucceededSlideInOnboardingBatch = (
  slides: (
    | SingleChannelFormRowFilledReadOnly
    | MultiChannelFormRowFilledReadOnly
  )[]
): number =>
  slides.filter((slide) =>
    slide.type === 'single-channel'
      ? hasOnlySuccessfulOnboardingStages(slide.onboardProgress)
      : slide.channels.every((channel) =>
          hasOnlySuccessfulOnboardingStages(channel.onboardProgress)
        )
  ).length;
