import { useEffect, useRef } from 'react';

function useEventListener<EventName extends keyof GlobalEventHandlersEventMap>(
  eventName: EventName,
  handler: (event: GlobalEventHandlersEventMap[EventName]) => void,
  element: HTMLElement | Window = window,
  capture = false
): void {
  // Create a ref that stores handler
  const savedHandler = useRef(handler);

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element?.addEventListener;
      if (!isSupported) {
        return;
      }

      // Create event listener that calls handler function stored in ref
      const eventListener = (event: GlobalEventHandlersEventMap[EventName]) => {
        savedHandler.current(event);
      };

      // Add event listener
      element.addEventListener(
        eventName,
        eventListener as EventListener,
        capture
      );

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(
          eventName,
          eventListener as EventListener,
          capture
        );
      };
    },
    [eventName, element, capture] // Re-run if eventName or element changes
  );
}

export default useEventListener;
