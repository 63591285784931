import * as Accordion from '@radix-ui/react-accordion';
import styled, { css } from 'styled-components';

export const $MenuItemStyle = css`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: ${({ theme }) => theme.spacings[64]}px;
  white-space: nowrap;
  padding: ${({ theme }) => `${theme.spacings[8]}px`};
  padding-left: ${({ theme }) => `${theme.spacings[16]}px`};
  text-decoration: none;
  width: 100%;
  background-color: inherit;
  transition: 400ms;
  color: ${({ theme }) => theme.colors.text};
  border: 0;
  ${({ theme }) => theme.fontStyles.baseBold}
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.light};
  }
`;

export const $AccordionTrigger = styled(Accordion.Trigger)`
  ${$MenuItemStyle}

  &[data-state='open'] .AccordionChevron {
    transform: rotate(180deg);
  }
`;

export const $AccordionItem = styled(Accordion.Item)<{ level: number }>`
  overflow: hidden;

  ${$AccordionTrigger} {
    text-indent: ${({ theme, level }) => theme.spacings[8] * (level - 1)}px;
  }
`;

export const $AccordionContent = styled(Accordion.Content)`
  overflow: hidden;
`;
