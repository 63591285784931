import React, { Dispatch, ReactElement } from 'react';

import { renderSlidesMetadataEditor } from '../../utils/renderSlidesMetadataEditor';
import { OnboardingCloudFile } from './FETCH_ONBOARDING_CLOUD_FILES';
import {
  OnboardingCloudFileWithMetadata,
  RequestMetadataStep,
} from './RequestMetadataStep.component';
import { TransferFilesStep } from './TransferFiles.component';

interface RequestMetadataStep {
  name: 'requestMetadata';
  files: OnboardingCloudFile[];
  title: 'Requesting Metadata';
}

interface SetFileMetadataStep {
  files: OnboardingCloudFileWithMetadata[];
  name: 'setFileMetadata';
  title: 'Slides Metadata';
}

interface TransferSlidesStep {
  name: 'transferFiles';
  title: 'Transfer Slides';
  batchId: string;
}

export type TransferCloudSlideStep =
  | RequestMetadataStep
  | SetFileMetadataStep
  | TransferSlidesStep;
/**
 * Renders the current step of the upload slides wizard. Handles step changing.
 */
export function TransferCloudSlidesSteps({
  step,
  onStepChange,
  authToken,
  apiUrl,
}: {
  step: TransferCloudSlideStep;
  onStepChange: Dispatch<TransferCloudSlideStep>;
  authToken: string | null;
  apiUrl: string;
}): ReactElement {
  switch (step.name) {
    case 'requestMetadata': // 1 step
      return (
        <RequestMetadataStep
          files={step.files}
          onFetchedMetadata={(filesWithMetadata) => {
            onStepChange({
              files: filesWithMetadata,
              name: 'setFileMetadata',
              title: 'Slides Metadata',
            });
          }}
        />
      );

    case 'setFileMetadata': // 2 step
      return renderSlidesMetadataEditor({
        apiUrl,
        authToken,
        files: step.files,
        submitLabel: 'transfer',
        onSuccessfulCreate: ({ createOnboardingBatch: { batchId } }) => {
          onStepChange({
            name: 'transferFiles',
            title: 'Transfer Slides',
            batchId,
          });
        },
        csv: undefined,
      });

    case 'transferFiles': // 3 step
      return <TransferFilesStep batchId={step.batchId} />;
  }
}
