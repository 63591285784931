import { OneLiner, TableSubRowCell, Toggle } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { WsiThumbnail } from '../../../../../../components';
import {
  $ThumbnailWrapper,
  StainingTableSubRow,
  StainingTableSubRowCell,
} from '../EditStainingsModal.styles';

export const EditStainingsFluorescenceRow = ({
  fluorescence,
  origin,
  fluorescenceVisible,
  name,
  switchStaining,
  rasterTileServerUrl,
  getToken,
}: {
  fluorescence: {
    id: string;
    channelsCount: number;
    isParentBlackLayer: boolean;
    firstChannel: string;
  };
  origin: string;
  subProjectId: string;
  fluorescenceVisible: boolean;
  name: string;
  switchStaining: (wsiId: string, type: string, value: boolean) => void;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}): ReactElement => {
  const onSetVisible = (value: boolean) => {
    switchStaining(origin, 'Fluorescence', value);
  };

  return (
    <StainingTableSubRow
      aria-label={`Assigned fluorescence`}
      key={fluorescence.id}
    >
      <TableSubRowCell>
        <$ThumbnailWrapper>
          <WsiThumbnail
            wsiId={fluorescence.firstChannel}
            getToken={getToken}
            rasterTileServerUrl={rasterTileServerUrl}
            size="small"
          />
        </$ThumbnailWrapper>
      </TableSubRowCell>

      <TableSubRowCell style={{ maxWidth: '20vw' }} colSpan={2}>
        {' '}
        <OneLiner>{`miF ${name} (${fluorescence.channelsCount} channels)`}</OneLiner>
      </TableSubRowCell>
      <TableSubRowCell>Fluorescence</TableSubRowCell>

      <StainingTableSubRowCell align="right" colSpan={2}>
        <Toggle
          componentTheme="transparent"
          active={fluorescenceVisible || !fluorescence.isParentBlackLayer}
          onChange={onSetVisible}
          label="Toggle fluorescence channels"
          disabled={!fluorescence.isParentBlackLayer}
        />
      </StainingTableSubRowCell>
    </StainingTableSubRow>
  );
};
