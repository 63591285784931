import {
  Button,
  Disclosure,
  IconButton,
  Link,
  OneLiner,
  TableCell,
  TableRow,
  TableSubRowSkeleton,
} from '@aignostics/components';
import { useToggle } from '@aignostics/hooks';
import { useLazyQuery } from '@apollo/client';
import { unionBy } from 'lodash';
import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { WsiThumbnail } from '../../../../../../components';
import { Wsi } from '../../../../../../types';
import { OnSetCoefficient } from '../AssignedSlidesTable';
import { GET_AVAILABLE_COEFFICIENTS } from '../GET_AVAILABLE_COEFFICIENTS';
import { GET_RELATED_LAYERS } from '../GET_RELATED_LAYERS';
import { FluorescenceRow } from './FluorescenceRow';
import { StainingRow } from './StainingRow';
const groupArrayBy = <T extends { [key: string]: string }>(
  array: T[],
  key: string
): Record<string, T[]> => {
  return array.reduce<Record<string, T[]>>(function (r, a) {
    r[a[key]] = r[a[key]] || [];
    r[a[key]].push(a);
    return r;
  }, Object.create({}));
};

export const WsiRow = ({
  wsi,
  subprojectId,
  onSetCoefficient,
  infoModalDisclosure,
  showMore,
  to,
  sortOrder,
  rasterTileServerUrl,
  getToken,
}: {
  subprojectId: string;
  wsi: Wsi;
  onSetCoefficient: OnSetCoefficient;
  infoModalDisclosure: Disclosure<Wsi>;
  showMore: boolean;
  to: string;
  sortOrder?: number;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}): ReactElement => {
  const location = useLocation();

  const [isExpanded, handleToggleStainingsClick] = useToggle(false);
  const hasActiveFluorescence = !wsi.isBlackLayer && wsi.fluorescenceCount;

  const hasActiveStainings = wsi.brightfieldCount > 1;

  const [getCoefficients, { data, loading }] = useLazyQuery<{
    availableCoefficients: {
      stainings: {
        registrationId: string;
        registrationName: string;
        referenceId: string;
        appliedRegistration: string;
      }[];
      fluorescence: {
        registrationId: string;
        registrationName: string;
        referenceId: string;
        appliedRegistration: string;
      }[];
    };
  }>(GET_AVAILABLE_COEFFICIENTS);

  const [getLayers, { data: stainingsData, loading: loadingStainingsData }] =
    useLazyQuery<{
      relatedLayers: {
        stainings: {
          id: string;
          name: string;
          isActive: boolean;
          stainingName: string;
          scanner: {
            vendor: string;
            model: string;
          };
        }[];
        fluorescence: {
          id: string;
          name: string;
          firstChannel: string | null;
          isActive: boolean;
          channelsCount: number;
        }[];
      };
    }>(GET_RELATED_LAYERS);

  const renderStaining = () => {
    return stainingsData?.relatedLayers.stainings.map((stainingLayer) => (
      <StainingRow
        reference={wsi.id}
        stainingLayer={{
          id: stainingLayer.id,
          isActive: stainingLayer.isActive,
          scanner: stainingLayer.scanner,
          staining: stainingLayer.stainingName,
        }}
        key="staining row"
        onSetCoefficient={onSetCoefficient}
        coefficientsLoading={loading}
        availableCoefficients={stainingCoefficients?.[stainingLayer.id] ?? []}
        rasterTileServerUrl={rasterTileServerUrl}
        getToken={getToken}
      />
    ));
  };

  const renderFluorescence = () => {
    if (!(!loadingStainingsData && hasActiveFluorescence)) return;

    return stainingsData?.relatedLayers.fluorescence.map(
      ({ id, name, firstChannel, channelsCount, isActive }) =>
        channelsCount ? (
          <FluorescenceRow
            reference={wsi.id}
            uuid={id}
            fluorescenceVisible={isActive}
            coefficientsLoading={loading}
            key="fluorescence row"
            name={name}
            onSetCoefficient={onSetCoefficient}
            availableCoefficients={multiplexCoefficients?.[id] ?? []}
            rasterTileServerUrl={rasterTileServerUrl}
            getToken={getToken}
            numberOfChannels={channelsCount}
            firstChannel={firstChannel ?? ''}
          />
        ) : null
    );
  };

  const stainingCoefficients =
    data?.availableCoefficients.stainings &&
    groupArrayBy(data?.availableCoefficients.stainings, 'referenceId');

  const multiplexCoefficients =
    data?.availableCoefficients.fluorescence &&
    groupArrayBy(
      unionBy(data?.availableCoefficients.fluorescence, 'registrationId'),
      'referenceId'
    );

  return (
    <React.Fragment key={wsi.id}>
      <tr style={{ height: '8px' }} />

      <TableRow
        aria-label={`Assigned ${sortOrder ? `#${sortOrder}` : ''} ${wsi.id}`}
      >
        <TableCell tooltipText={wsi.id}>
          <div style={{ width: '48px', height: '48px' }}>
            <WsiThumbnail
              wsiId={wsi.id}
              getToken={getToken}
              rasterTileServerUrl={rasterTileServerUrl}
              size="small"
            />
          </div>
        </TableCell>
        <TableCell
          tooltipText={wsi.name}
          style={{ maxWidth: '25ch', minWidth: '25ch' }}
        >
          <Link href={`${to}${location.search}`} target="_blank">
            <OneLiner>{wsi.name}</OneLiner>
          </Link>
        </TableCell>
        <TableCell align="center">
          <IconButton
            description="Open slide metadata"
            icon="FileText"
            size="button"
            onClick={() => {
              infoModalDisclosure.open(wsi);
            }}
          />
        </TableCell>
        <TableCell align="center">{wsi.regionsOfInterestCount}</TableCell>
        <TableCell align="center">{wsi.brightfieldCount}</TableCell>
        <TableCell align="center">{wsi.fluorescenceCount}</TableCell>
        <TableCell align="center">{wsi.annotationsCount}</TableCell>
        <TableCell align="center">{wsi.taggersCount}</TableCell>
        <TableCell align="center">{wsi.overlaysCount}</TableCell>
        {showMore ? (
          <TableCell>
            {hasActiveStainings || hasActiveFluorescence ? (
              <Button
                small
                variant="ghost"
                onClick={() => {
                  handleToggleStainingsClick();
                  void getLayers({
                    variables: {
                      subProjectId: subprojectId,
                      wsiId: wsi.id,
                    },
                  });

                  void getCoefficients({
                    variables: { subProjectId: subprojectId, wsiId: wsi.id },
                  });
                }}
              >
                {`${isExpanded ? 'Hide' : 'Show'} Stainings`}
              </Button>
            ) : null}
          </TableCell>
        ) : null}
      </TableRow>
      {isExpanded && loadingStainingsData && <TableSubRowSkeleton rows={10} />}
      {isExpanded && !loadingStainingsData ? (
        <>
          <>{renderStaining()}</>
          <>{renderFluorescence()}</>
        </>
      ) : null}
    </React.Fragment>
  );
};
