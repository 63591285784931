import { prettyFormatBytes } from '@aignostics/components';
import {
  UPLOADING_FILE_STATUS,
  useUploadFilesContext,
} from '../pages/UploadSlides';

export function useOnboardingPageTitleIndicator(
  defaultTitle: string
): string | null {
  const UPLOAD_IN_PROGRESS_EMOJI = '⏫';
  const { uploadState } = useUploadFilesContext();
  if (uploadState.status !== UPLOADING_FILE_STATUS.UPLOADING) {
    return null;
  }

  const leftBytes = uploadState.progress.reduce(
    (acc, { fileSize, progress }) => acc + fileSize - progress,
    0
  );
  const uploadProgressPart = `${UPLOAD_IN_PROGRESS_EMOJI} ${prettyFormatBytes(
    leftBytes
  )} left`;
  return [uploadProgressPart, defaultTitle].join(' | ');
}
